import { useState, useEffect, useContext } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import UserContext from '../../context/user-context';
import ChipSelector from '../ui/ChipSelector';
import { APP_CONFIG } from '../../config';
import useHttp from '../../utils/http';

const AccountProfile = (props) => {

  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();
  const [userProfileImage, setUserProfileImage] = useState(null);
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (reqExtra === APP_CONFIG.APIS.GET_PROFILE_IMAGE && data) {
      setUserProfileImage(data.employee.employeeThumbnail);
    }
  }, [data, reqExtra, isOpen, isLoading, error, userProfileImage]);

  useEffect(() => {
    if (userContext.userName) {
      sendRequest(`${APP_CONFIG.APIS.GET_PROFILE_IMAGE}`, 'GET', null, APP_CONFIG.APIS.GET_PROFILE_IMAGE);
    }
  }, [userContext.userName]);

  return (
    <UserContext.Consumer>
      {context => (
        <Card {...props}>
          <CardContent>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {userProfileImage && context.userName ?
                <Avatar
                  alt={context.userName}
                  src={userProfileImage}
                  sx={{
                    height: 75,
                    width: 75,
                    mb: 2
                  }}
                />
                :
                <Avatar
                  alt="user profile image"
                  sx={{
                    height: 75,
                    width: 75,
                    mb: 2
                  }}
                />
              }
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h3"
              >
                {context.userName}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
              >
                Roles
              </Typography>
              <ChipSelector id="roles-selector" value={context.userRoles.map(e => { return { name: e } })} placeholder={"No roles to show"} readOnly />
            </Box>
          </CardContent>
          <Divider />
        </Card>
      )}
    </UserContext.Consumer>
  )
};

export default AccountProfile;
