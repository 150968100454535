import { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Chip, Tooltip } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    muiChip: {
        [theme.breakpoints.down('md')]: {
            maxWidth: 150,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 180,
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 230,
        },
    },
}));

const UserChip = (props) => {
    const classes = useStyles();
    useEffect(() => { }, [props.owner]);

    return (
        <Fragment>
            {props.owner && <Tooltip title={props.owner} arrow placement="top">
                <Chip
                    classes={{ label: classes.muiChip }}
                    size="small"
                    avatar={<Avatar alt={props.owner} src=""
                        sx={{ width: 24, height: 24, fontSize: 16, }}>
                        {props.owner.length ? props.owner.charAt(0).toUpperCase() : ""}
                    </Avatar>}
                    label={props.owner}
                    variant="outlined"
                />
            </Tooltip>}
        </Fragment>
    );
};

export default UserChip;