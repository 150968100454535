import { Avatar, Box, Typography } from "@material-ui/core";
import { EyeOff as VisibilityOffIcon } from 'react-feather';

const PreviewNotAvailable = (props) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pb: 3
                }}
            >
                <Avatar
                    alt="Product"
                    // src={product.media}
                    variant="square"
                    sx={{ width: 56, height: 56 }}
                >
                    <VisibilityOffIcon size="36px" />
                </Avatar>
            </Box>
            <Typography
                align="center"
                color="textPrimary"
                variant="h4"
            >
                Preview not available
            </Typography>
        </>);
}

export default PreviewNotAvailable;