import { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Box,
  List,
} from '@material-ui/core';

import {
  Heart as HeartIcon,
  Home as HomeIcon,
  Sliders as SlidersIcon,
} from 'react-feather';
import NavItem from './NavItem';
import { APP_CONFIG } from '../config';


const items = [
  {
    href: APP_CONFIG.PAGES.HOME,
    icon: () => <HomeIcon />,
    title: 'Home'
  },
  {
    href: APP_CONFIG.PAGES.FAVOURITES,
    icon: () => <HeartIcon />,
    title: 'Favourites'
  },
];

export default function MiniDrawer({ page }) {

  useEffect(() => { }, [page]);

  return (
    <Box
      sx={{
        mt: 8,
        px: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: 'max-content',
        backgroundColor: '#ECECEC'
      }}
    >
      <Box sx={{ pt: 1 }}>
        <List>
          {items.map((item, index) => {
            return (
              <NavItem
                selected={page && page.startsWith(item.href)}
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            )
          }
          )}
          <NavItem
            selected={page && page.startsWith(APP_CONFIG.PAGES.SETTINGS)}
            href={APP_CONFIG.PAGES.SETTINGS}
            key={'settings-nav-item'}
            title={"Settings"}
            icon={() => <SlidersIcon />}
          />
        </List>
      </Box>
    </Box>
  );
}
