import { useState, useEffect } from 'react';
import {
    Menu,
    MenuItem,
    ListItemIcon,
    Typography
} from '@material-ui/core';
import { checkForUserAccess } from '../../utils/oauth';

const ITEM_HEIGHT = 48;

const OptionMenu = (props) => {
    const open = Boolean(props.anchorEl);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let optionsTemp = [];
        props.options.forEach(option => {
            if (option.access && !checkForUserAccess(option.access, option?.owner)) {
                return;
            }
            optionsTemp.push(option);
        });
        setOptions(optionsTemp);
    }, [props.anchorEl, props.options]);

    return (
        <Menu
            id="long-menu"
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={props.anchorEl}
            open={open}
            onClose={props.handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '25ch',
                },
            }}
        >
            {options.map((option) => {
                return (<span key={"option-menu" + option.title}>{
                    option.parentComponent ?
                        <span>{option.parentComponent(
                            <MenuItem key={option.title} onClick={option.action} dense sx={{ color: option.color }}>
                                <ListItemIcon sx={{ minWidth: 28, }}>
                                    {option.icon}
                                </ListItemIcon>
                                <Typography
                                    display="inline"
                                    variant="body2"
                                    noWrap
                                >
                                    {option.title}
                                </Typography>

                            </MenuItem>)}</span>
                        :
                        <MenuItem key={option.title} onClick={option.action} dense sx={{ color: option.color }}>
                            <ListItemIcon sx={{ minWidth: 28, color: 'inherit' }} >
                                {option.icon}
                            </ListItemIcon>
                            <Typography
                                display="inline"
                                variant="body2"
                                noWrap
                            >
                                {option.title}
                            </Typography>

                        </MenuItem>
                }</span>

                )
            })}
        </Menu>
    );
}

export default OptionMenu
