import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Tab, Typography, } from '@material-ui/core';
import Account from './Account';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import TabList from '@material-ui/lab/TabList';
import { APP_NAME, APP_CONFIG, APP_STORE_INFO } from '../config';
import { checkForUserAccess, DEFINED_ACCESS } from '../utils/oauth';
import TagsPage from './TagsPage';
import RolesPage from './RolesPage';

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const SettingsPage = (props) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    changeView(newValue);
    setValue(newValue);
  };

  const changeView = (newView) => {
    var view = APP_CONFIG.QUERY_VALUES.ACCOUNT;
    switch (newView) {
      case "2":
        view = APP_CONFIG.QUERY_VALUES.TAGS;
        break;
      case "3":
        view = APP_CONFIG.QUERY_VALUES.ROLES;
        break;
      default:
        view = APP_CONFIG.QUERY_VALUES.ACCOUNT;
        break;
    }
    var newPath = window.location.protocol + "//" + window.location.host + window.location.pathname + '?view=' + view;
    window.history.pushState({ path: newPath }, '', newPath);
  };

  useEffect(() => {
    changeView(props.view);
    setValue(props.view);
  }, [props.view])

  return (
    <>
      <Helmet>
        <title>Settings | {APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          p: 3
        }}
      >
        <Box sx={{ py: 2 }}>
          <Typography variant="h2" gutterBottom>Settings</Typography>
        </Box>
        <Box sx={{ width: '100%', backgroundColor: 'background.paper', }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} indicatorColor='secondary' aria-label="tab-list-settings">
                <LinkTab label="Account" value="1" href="/drafts" />
                {checkForUserAccess(DEFINED_ACCESS.ADMIN) && <LinkTab label="Tags" value="2" href="/trash" />}
                {checkForUserAccess(DEFINED_ACCESS.SUPER) && <LinkTab label="Roles" value="3" href="/spam" />}
              </TabList>
              <TabPanel value="1"><Account /></TabPanel>
              <TabPanel value="2"><>{checkForUserAccess(DEFINED_ACCESS.ADMIN) && <TagsPage handleSnackbar={props.handleSnackbar} />}</></TabPanel>
              <TabPanel value="3">{checkForUserAccess(DEFINED_ACCESS.SUPER) && <RolesPage handleSnackbar={props.handleSnackbar} />}</TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Box>
    </>
  );
}

export default SettingsPage;
