import { useState, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import useHttp from '../utils/http';
import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Box,
  Typography
} from '@material-ui/core';
import {
  Container,
  Grid
} from '@material-ui/core';
import {
  Save as SaveIcon,
  Plus as PlusIcon,
  RefreshCw as ResetIcon,
  Trash as DeleteIcon,
  X as CancelIcon,
} from 'react-feather';
import { FixedSizeList } from 'react-window';
import SearchBar from '../components/ui/SearchBar';
import { APP_NAME, APP_CONFIG } from '../config';
import { validateInputLength } from '../utils/validate';
import EmptyText from '../components/ui/EmptyText';
import ConfirmationDialog from '../components/dialog/ConfirmationDialog';
import { DEFINED_ACCESS, getAccessForRole } from '../utils/oauth';


const EMPTY_VALUE_ACCESS = DEFINED_ACCESS.VIEW.name;

const RolesPage = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentTitle, setCurrentTitle] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [previousAccess, setPreviousAccess] = useState(EMPTY_VALUE_ACCESS);
  const [currentAccess, setCurrentAccess] = useState(EMPTY_VALUE_ACCESS);
  const [roles, setRoles] = useState([]);
  const [searchRoles, setSearchRoles] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchOrder, setSearchOrder] = useState('asc');
  const [addingNewRole, setAddingNewRole] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(null);

  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setAddingNewRole(false);
    let roleName = searchRoles[index]?.name;
    let roleAccess = getAccessForRole(roleName);
    setCurrentTitle(roleName);
    setEditTitle(roleName);
    setPreviousAccess(roleAccess);
    setCurrentAccess(roleAccess);
  };

  const handleItemUpdateLoad = (index, rolesArray) => {
    setSelectedIndex(index);
    setAddingNewRole(false);
    let roleName = rolesArray[index]?.name;
    let roleAccess = getAccessForRole(roleName);
    setCurrentTitle(roleName);
    setCurrentAccess(roleAccess);
  };

  const handleAccessChange = (event) => {
    setCurrentAccess(event.target.value);
  };

  const getRoles = () => {
    sendRequest(APP_CONFIG.APIS.GET_ROLES, 'GET', null, "GET_ROLES");
  };

  const createRole = () => {
    var payload = {
      "name": editTitle,
      "access": currentAccess || EMPTY_VALUE_ACCESS
    };
    sendRequest(APP_CONFIG.APIS.CREATE_ROLE, 'POST', JSON.stringify(payload), "CREATE_ROLE");
  };

  const updateRole = () => {
    var roleId = searchRoles[selectedIndex]?.id;
    if (roleId) {
      var payload = {
        "id": roleId,
        "name": editTitle,
        "access": currentAccess || EMPTY_VALUE_ACCESS
      };
      sendRequest(APP_CONFIG.APIS.UPDATE_ROLE, 'PATCH', JSON.stringify(payload), "UPDATE_ROLE");
    }
  };

  const handleDeleteRole = () => {
    var roleId = searchRoles[selectedIndex]?.id;
    if (roleId) {
      handleConfirmationDialog({
        title: "WARNING! You are about to delete the following role.",
        text: currentTitle,
        confirmAction: () => { deleteRole(roleId); }
      });
    }
  };

  const deleteRole = (roleId) => {
    sendRequest(APP_CONFIG.APIS.DELETE_ROLE + "/" + roleId, 'DELETE', null, "DELETE_ROLE");
  };

  const handleNewRole = (isStart) => () => {
    setSelectedIndex(null);
    setCurrentTitle("");
    setEditTitle("");
    setCurrentAccess(EMPTY_VALUE_ACCESS);
    setAddingNewRole(isStart);
  };

  const handleChangeCancel = () => {
    handleReset();
  };

  const handleRoleName = (event) => {
    let roleName = validateInputLength(event.target.value, 50);
    setEditTitle(roleName);
  };

  const handleSearchText = (value) => {
    setSearchText(value);
  };

  const handleSearchOrder = (isAscending) => {
    setSearchOrder(isAscending ? 'asc' : 'desc');
  };

  const handleReset = (isFull) => {
    if (isFull) {
      setCurrentTitle("");
      setEditTitle("");
      setCurrentAccess(EMPTY_VALUE_ACCESS);
      setCurrentAccess(EMPTY_VALUE_ACCESS);
      setSelectedIndex(null);
    } else {
      if (currentTitle) {
        setEditTitle(currentTitle);
        setCurrentAccess(EMPTY_VALUE_ACCESS);
      }
    }
    setAddingNewRole(false)
  };

  const handleSave = () => {
    createRole();
  };

  const handleSaveChanges = () => {
    updateRole();
  };

  const handleConfirmationDialog = (content) => {
    setConfirmationDialog(content);
  };

  const setFilteredCollections = () => {
    let rolestemp = roles;
    rolestemp.sort((a, b) => {
      let nameA = a.name.toLowerCase();
      let nameB = b.name.toLowerCase();
      let searchMultiplier = searchOrder === 'desc' ? -1 : 1;

      return (nameA > nameB) ? (1 * searchMultiplier) : (nameA < nameB) ? (-1 * searchMultiplier) : 0;
    });
    // setRoles(rolestemp);
    rolestemp = rolestemp.filter(e => { return e.name.toLowerCase().includes(searchText.toLowerCase()) });
    // setSearchRoles(rolestemp);

    setSearchRoles(prevState => {
      //To keep updated value when it loads
      if (selectedIndex !== null) {
        let roleId = prevState[selectedIndex]?.id;
        if (roleId) {
          let index = rolestemp.findIndex(e => e?.id === roleId);
          (index >= 0) && handleItemUpdateLoad(index, rolestemp);
        }
      }
      return rolestemp;
    });
  };

  const getIsChanged = () => {
    if (currentTitle === editTitle && currentAccess === previousAccess) {
      return false;
    }
    return true;
  };

  const renderRow = (props) => {
    const { index, style } = props;

    return (
      // <Fragment key={index}>
      <ListItem key={index} style={style} component="div" disablePadding >
        <ListItemButton selected={selectedIndex === index}
          //  autoFocus={Boolean(selectedIndex === index)}
          onClick={(event) => { handleListItemClick(event, index); }}>
          <ListItemText primary={searchRoles[index].name} />
        </ListItemButton>
      </ListItem>
      // </Fragment>
    );
  };

  const VirtualizedRolesList = () => {
    return (
      <Paper
        sx={{ width: '60%', height: '100%', bgcolor: 'background.paper' }}
      >
        <FixedSizeList
          width="100%"
          height={500}
          itemCount={searchRoles.length}
          itemSize={46}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </Paper>
    );
  };

  useEffect(() => {
    switch (reqExtra) {
      case "GET_ROLES":
        if (data) {
          var rolesMap = {};
          var rolesArray = [];
          data.forEach(role => {
            if (role.id) {
              rolesMap[role.id] = role;
              rolesArray.push(role);
            }
          });

          setRoles(rolesArray);
        }
        break;
      case "CREATE_ROLE":
        if (data && !error) {
          handleReset();
          if (data?.message) {
            props.handleSnackbar(data.message, "success");
          }
          getRoles();
          window.location.reload();
        } else if (error) {
          if (error?.message) {
            props.handleSnackbar(error.message, "error");
            return;
          }
          props.handleSnackbar("Failed to create role!", "error");
        }
        break;
      case "UPDATE_ROLE":
        if (data && !error) {
          if (data?.message) {
            props.handleSnackbar(data.message, "success");
          }
          getRoles();
          window.location.reload();
        } else if (error) {
          if (error?.message) {
            props.handleSnackbar(error.message, "error");
            return;
          }
          props.handleSnackbar("Failed to update role!", "error");
        }
        break;
      case "DELETE_ROLE":
        if (data && !error) {
          handleReset(true);
          if (data?.message) {
            props.handleSnackbar(data.message, "success");
          }
          getRoles();
        } else if (error) {
          if (error?.message) {
            props.handleSnackbar(error.message, "error");
            return;
          }
          props.handleSnackbar("Failed to delete role!", "error");
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  useEffect(() => {
    setFilteredCollections();
  }, [roles, searchText, searchOrder]);

  useEffect(() => {

  }, [searchRoles]);

  useEffect(() => {
    getRoles();
  }, []);


  return (
    <Fragment>
      <Helmet>
        <title>Roles | {APP_NAME}</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12}>
            {isLoading && <LinearProgress color="secondary" />}
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ mb: 6 }}
          >
            <Stack direction="column" spacing={2}>
              <span>
                <SearchBar
                  value={searchText}
                  handleSearch={handleSearchText}
                  isAlphabetical={searchOrder === 'asc' ? true : false}
                  handleAlphabetical={handleSearchOrder}
                />
              </span>
              <span>
                {searchRoles.length ? <VirtualizedRolesList /> : <EmptyText text="No roles to show" />}
              </span>
            </Stack>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ mb: 6 }}
          >
            <Grid
              container
              direction="column"
              spacing={4}
            >
              <Grid item xs={12}>
                <Stack direction="row"
                  justifyContent="flex-end"
                  alignItems="center" spacing={2}>
                  {addingNewRole ?
                    <Fragment>
                      <span>
                        <Button
                          size="medium"
                          startIcon={<CancelIcon />}
                          variant="contained"
                          color="primary"
                          sx={{ minWidth: 128 }}
                          onClick={handleNewRole(false)}
                        >
                          Discard
                        </Button>
                      </span>
                      <span>
                        <Button
                          size="medium"
                          disabled={!editTitle}
                          startIcon={<SaveIcon />}
                          variant="contained"
                          color="secondary"
                          sx={{ minWidth: 128 }}
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                      </span>
                    </Fragment> :

                    selectedIndex !== null ? <Fragment>
                      <span>
                        <Button
                          size="medium"
                          disabled={selectedIndex !== null && getIsChanged()}
                          startIcon={<DeleteIcon />}
                          variant="contained"
                          color="error"
                          sx={{ minWidth: 128 }}
                          onClick={handleDeleteRole}
                        >
                          Delete
                        </Button>
                      </span>
                      <span>
                        <Button
                          size="medium"
                          disabled={!getIsChanged()}
                          startIcon={<ResetIcon />}
                          variant="contained"
                          color="primary"
                          sx={{ minWidth: 128 }}
                          onClick={handleChangeCancel}
                        >
                          Reset
                        </Button>
                      </span>
                      <span>
                        <Button
                          size="medium"
                          disabled={!editTitle || !getIsChanged()}
                          startIcon={<SaveIcon />}
                          variant="contained"
                          color="secondary"
                          sx={{ minWidth: 128 }}
                          onClick={handleSaveChanges}>
                          Save Changes
                        </Button>
                      </span>
                    </Fragment> : null
                  }
                  <span>
                    <Button
                      size="medium"
                      disabled={addingNewRole}
                      startIcon={<PlusIcon />}
                      variant="contained"
                      color="secondary"
                      sx={{ minWidth: 128 }}
                      onClick={handleNewRole(true)}
                    >
                      New Role
                    </Button>
                  </span>
                </Stack>
              </Grid>
              <Grid item xs={12}>{addingNewRole || currentTitle ? <Stack direction="column"
                justifyContent="center"
                alignItems="center" spacing={2}>
                <span>
                  <Chip
                    variant='outlined'
                    label={editTitle ? editTitle : "Sample role"}
                    color="primary"
                    sx={{
                      height: 32,
                      fontSize: 22,
                      padding: '1px 6px',
                      borderRadius: '4px'
                    }}
                  />
                </span>
                <span>
                  <TextField
                    id="outlined-basic"
                    label="Role name"
                    variant="outlined"
                    value={editTitle}
                    onChange={handleRoleName}
                    autoComplete="off"
                  />
                </span>
                <span>
                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="access-select-label">Access</InputLabel>
                    <Select
                      labelId="access-open-select-label"
                      id="access-open-select"
                      value={currentAccess}
                      label="Access"
                      onChange={handleAccessChange}
                    >
                      <MenuItem value={EMPTY_VALUE_ACCESS}><em>View</em></MenuItem>
                      <MenuItem value={DEFINED_ACCESS.EDIT.name}>Edit</MenuItem>
                      <MenuItem value={DEFINED_ACCESS.CREATE.name}>Create</MenuItem>
                      <MenuItem value={DEFINED_ACCESS.ADMIN.name}>Admin</MenuItem>
                      <MenuItem value={DEFINED_ACCESS.SUPER.name}>Super Admin</MenuItem>
                    </Select>
                  </FormControl>
                </span>
              </Stack> :
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>
                  <Typography variant="overline" display="block">
                    Create a new role or select one from the list to edit.
                  </Typography>
                </Box>
              }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {confirmationDialog && <ConfirmationDialog content={confirmationDialog} handleOpen={handleConfirmationDialog} />}
    </Fragment>
  )
};

export default RolesPage;
