import { useEffect, useReducer, Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Container,
  Grid,
  Fab,
  LinearProgress,
  Typography,
  Zoom
} from '@material-ui/core';

import {
  Plus as PlusIcon
} from 'react-feather';

import LinkCard from '../components/link/LinkCard';
import AddNewLinkGroup from '../components/dialog/AddNewLinkGroup';
import UpdateLinkGroup from '../components/dialog/UpdateLinkGroup';
import ViewLinkGroup from '../components/dialog/ViewLinkGroup';
import useHttp from '../utils/http';
import LinkGroupContext from '../context/link-group-context';
import ImagePreview from '../components/dialog/ImagePreview';
import EmptyText from '../components/ui/EmptyText';
// import { scrollToTop } from '../utils/utils';
import { APP_CONFIG, APP_STORE_INFO } from '../config.js';
import { getUserName } from '../utils/oauth';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const ACTIONS = {
  SET_GROUP_LINKS: 'SET_GROUP_LINKS',
  SET_GROUP_LINKS_MAP: 'SET_GROUP_LINKS_MAP',
  SET_ROLES_MAP: 'SET_ROLES_MAP',
  SET_TAGS_MAP: 'SET_TAGS_MAP',
  SET_FAVOURITES_MAP: 'SET_FAVOURITES_MAP',
  SET_ADD_NEW_LINK_GROUP_DIALOG: 'SET_ADD_NEW_LINK_GROUP_DIALOG',
  SET_UPDATE_LINK_GROUP_DIALOG: 'SET_UPDATE_LINK_GROUP_DIALOG',
  SET_VIEW_LINK_GROUP_DIALOG: 'SET_VIEW_LINK_GROUP_DIALOG',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_SEARCH_TAGS: 'SET_SEARCH_TAGS',
  SET_IMAGE_PREVIEW: 'SET_IMAGE_PREVIEW',
  HANDLE_RESET: 'HANDLE_RESET',
}

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute !important',
    bottom: theme.spacing(4),
    right: theme.spacing(5),
    textTransform: 'none !important'
  },
}));
//TODO
function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    // disableHysteresis: true,
    threshold: 10,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

const linksReducer = (curLeaveState, action) => {
  switch (action.type) {
    case ACTIONS.SET_GROUP_LINKS:
      return { ...curLeaveState, linkGroups: action.linkGroups, groupLinksMap: action.groupLinksMap, groupLinksTagsMap: action.groupLinksTagsMap, groupLinksRolesMap: action.groupLinksRolesMap }
    case ACTIONS.SET_TAGS_MAP:
      return { ...curLeaveState, tagsMap: action.tagsMap }
    case ACTIONS.SET_ROLES_MAP:
      return { ...curLeaveState, rolesMap: action.rolesMap }
    case ACTIONS.SET_FAVOURITES_MAP:
      return { ...curLeaveState, favouritesMap: action.favouritesMap }
    case ACTIONS.SET_IMAGE_PREVIEW:
      return { ...curLeaveState, imagePreview: action.imagePreview }
    case ACTIONS.SET_ADD_NEW_LINK_GROUP_DIALOG:
      return { ...curLeaveState, isOpenAddNewLinkGroup: action.isOpenAddNewLinkGroup }
    case ACTIONS.SET_UPDATE_LINK_GROUP_DIALOG:
      return { ...curLeaveState, isOpenUpdateGroupLink: action.isOpenUpdateGroupLink, groupLinkId: action.groupLinkId }
    case ACTIONS.SET_VIEW_LINK_GROUP_DIALOG:
      return { ...curLeaveState, isOpenViewGroupLink: action.isOpenViewGroupLink, groupLinkId: action.groupLinkId }
    default:
      throw new Error('Should not get here');
  }
}

const searchReducer = (curLeaveState, action) => {
  switch (action.type) {
    case ACTIONS.SET_SEARCH_TEXT:
      return { ...curLeaveState, searchText: action.searchText }
    case ACTIONS.SET_SEARCH_TAGS:
      return { ...curLeaveState, searchTags: action.searchTags }
    default:
      throw new Error('Should not get here');
  }
}

const Favourites = (props) => {
  const classes = useStyles();
  const [{ linkGroups, groupLinksMap, groupLinksTagsMap, tagsMap, rolesMap, groupLinksRolesMap, favouritesMap, isOpenAddNewLinkGroup, isOpenUpdateGroupLink, isOpenViewGroupLink, groupLinkId, imagePreview }, dispatchLinks] = useReducer(linksReducer,
    { linkGroups: [], groupLinksMap: {}, groupLinksTagsMap: {}, tagsMap: {}, rolesMap: {}, groupLinksRolesMap: {}, favouritesMap: {}, isOpenAddNewLinkGroup: false, isOpenUpdateGroupLink: false, isOpenViewGroupLink: false, groupLinkId: null, imagePreview: null });
  const [{ searchText, searchTags }, dispatchSearch] = useReducer(searchReducer, { searchText: "", searchTags: [] })
  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();

  const [isLoadingLinks, setIsLoadingLinks] = useState(false);


  const getLinkGroups = () => {
    if (searchText || searchTags.length) {
      getLinkGroupsWithSearch();
    } else {
      //TODO
      sendRequest(APP_CONFIG.APIS.GET_FAVOURITE_LINK_GROUPS, 'GET', null, APP_CONFIG.APIS.GET_FAVOURITE_LINK_GROUPS);
    }
  };

  //TODO
  const getLinkGroupsWithSearch = () => {
    var payload = {
      search: searchText,
      tags: searchTags.map(tag => tag.id)
    };
    sendRequest(APP_CONFIG.APIS.GET_FAVOURITE_LINK_GROUPS, 'GET', JSON.stringify(payload), APP_CONFIG.APIS.GET_FAVOURITE_LINK_GROUPS);
  };

  const getTags = () => {
    sendRequest(APP_CONFIG.APIS.GET_TAGS, 'GET', null, "GET_TAGS");
  };

  const getRoles = () => {
    sendRequest(APP_CONFIG.APIS.GET_ROLES, 'GET', null, "GET_ROLES");
  };

  const getFavourites = () => {
    //TODO
    sendRequest(APP_CONFIG.APIS.GET_FAVOURITES, 'GET', null, "GET_FAVOURITES");
  };

  const changeFavourite = (linkGroupId, isAdd) => {
    var payload = {
      "linkGroupId": linkGroupId,
      "email": getUserName(),
      "isAdd": isAdd
    }
    sendRequest(APP_CONFIG.APIS.CHANGE_FAVOURITE, 'POST', JSON.stringify(payload), "CHANGE_FAVOURITE");
  };

  //Event handlers
  const handleAddNewLinkGroup = (isOpen) => {
    dispatchLinks({ type: ACTIONS.SET_ADD_NEW_LINK_GROUP_DIALOG, isOpenAddNewLinkGroup: isOpen, });
  };

  const handleUpdateGroupLink = (isOpen, id) => {
    dispatchLinks({ type: ACTIONS.SET_UPDATE_LINK_GROUP_DIALOG, isOpenUpdateGroupLink: isOpen, groupLinkId: id, });
  };

  const handleViewGroupLink = (isOpen, id) => {
    dispatchLinks({ type: ACTIONS.SET_VIEW_LINK_GROUP_DIALOG, isOpenViewGroupLink: isOpen, groupLinkId: id, });
  };

  const handleSearchText = (event) => {
    dispatchSearch({ type: ACTIONS.SET_SEARCH_TEXT, searchText: event.target.value, });
  };

  // const handleSearchTags = (tags) => {
  //   dispatchSearch({ type: ACTIONS.SET_SEARCH_TAGS, searchTags: tags, });
  // };

  const handleImagePreview = (imagePreview) => {
    dispatchLinks({ type: ACTIONS.SET_IMAGE_PREVIEW, imagePreview: imagePreview });
  };

  const handleFavourite = (linkGroupId) => {
    //Collection ID is dropped from map
    // if (event.target.checked) {
    //   favouritesMap[linkGroupId] = { linkGroupId: linkGroupId };
    //   changeFavourite(linkGroupId, true);
    // } else {
    favouritesMap[linkGroupId] = null;
    changeFavourite(linkGroupId, false);
    // }

    dispatchLinks({ type: ACTIONS.SET_FAVOURITES_MAP, favouritesMap: favouritesMap });
  };

  useEffect(() => {
    getLinkGroups();
    getTags();
    getRoles();
    getFavourites();

  }, []);

  useEffect(() => {
    getLinkGroups();

  }, [searchTags]);

  useEffect(() => {
    if (error) {
      // setErrorOpen(true);
    }
    switch (reqExtra) {
      case APP_CONFIG.APIS.GET_FAVOURITE_LINK_GROUPS:
        setIsLoadingLinks(isLoading);
        if (data) {
          var groupLinksMapNew = {}
          var groupLinksTagsMap = {};
          var groupLinksRolesMap = {};
          if (data.linkGroups) {
            data.linkGroups.forEach(element => {
              var linkElement = {
                id: element.id,
                link: element.link,
                validTo: element.validTo,
                validFrom: element.validFrom,
                versionName: element.versionName,
              };
              if (groupLinksMapNew[element.linkGroupId]) {
                var linkGroupElement = groupLinksMapNew[element.linkGroupId];
                var linksArray = linkGroupElement.links.slice();
                linksArray.push(linkElement);
                linkGroupElement.links = linksArray;
              } else {
                var elementToPush = {
                  title: element.title,
                  description: element.description,
                  linkGroupId: element.linkGroupId,
                  links: [linkElement],
                  preview: element.preview,
                  isVerified: element.isVerified,
                  isRecurring: element.isRecurring,
                  createdDate: element.createdDate,
                  addedBy: element.addedBy,
                };
                groupLinksMapNew[element.linkGroupId] = elementToPush;
              }
              if (element.roles) {
                groupLinksRolesMap[element.linkGroupId] = element.roles.split(',');
              }
            });

            if (data.tags) {
              data.tags.forEach((e) => {
                groupLinksTagsMap[e.linkGroupId] = e.tagIds.split(',');
              });
            }

          }

          dispatchLinks({ type: ACTIONS.SET_GROUP_LINKS, linkGroups: Object.values(groupLinksMapNew), groupLinksMap: groupLinksMapNew, groupLinksTagsMap: groupLinksTagsMap, groupLinksRolesMap: groupLinksRolesMap });
        }
        break;
      case "GET_TAGS":
        if (data) {
          var tagsMap = {};
          data.forEach(tag => {
            if (tag.id) {
              tagsMap[tag.id] = tag;
            }
          });
          dispatchLinks({ type: ACTIONS.SET_TAGS_MAP, tagsMap: tagsMap });
        }
        break;
      case "GET_ROLES":
        if (data) {
          var rolesMap = {};
          data.forEach(role => {
            if (role.id) {
              rolesMap[role.id] = role;
            }
          });
          dispatchLinks({ type: ACTIONS.SET_ROLES_MAP, rolesMap: rolesMap });
        }
        break;
      case "GET_FAVOURITES":
        if (data) {
          var favouritesMap = {};
          data.forEach(favourite => {
            if (favourite.linkGroupId) {
              favouritesMap[favourite.linkGroupId] = favourite;
            }
          });
          dispatchLinks({ type: ACTIONS.SET_FAVOURITES_MAP, favouritesMap: favouritesMap });
        }
        break;
      case "CHANGE_FAVOURITE":
        if (!error && data && data.message) {
          getFavourites();
          props.handleSnackbar(data.message, "success");
        } else if (error) {
          var errorMessage = "Error updating favourites!";
          if (data && data.message) {
            errorMessage = data.message;
          }
          getFavourites();
          props.handleSnackbar(errorMessage, "error");
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  const functions = {
    handleUpdateGroupLink,
    handleFavourite,
    handleViewGroupLink,
  };

  return (
    <Fragment>
      <LinkGroupContext.Provider value={{
        linkGroups, groupLinksMap, groupLinksTagsMap, tagsMap, rolesMap, isOpenAddNewLinkGroup, isOpenUpdateGroupLink, groupLinkId, handleSnackbar: props.handleSnackbar
      }}>
        <Helmet>
          <title>Favourites | WSO2 App Store</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            {/* <LinkGroupListToolbar searchTags={searchTags} handleAddNewLinkGroup={handleAddNewLinkGroup} searchText={searchText} handleSearchText={handleSearchText} getLinkGroups={getLinkGroups} tagsMap={tagsMap} handleSearchTags={handleSearchTags} /> */}
            <Box sx={{ pt: 2 }}>
              <Typography variant="h2" gutterBottom>Favourites</Typography>
            </Box>
            {!isLoadingLinks && !linkGroups.length && (
              <Box>
                <span>
                  <Typography variant="subtitle1">{APP_STORE_INFO.FAVOURITES_PAGE_DESCRIPTION}</Typography>
                </span>
              </Box>
            )}
            <Box>
              <Grid
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  {isLoadingLinks && <LinearProgress color="secondary" />}
                </Grid>
                {linkGroups.length ?
                  <>{
                    linkGroups.map((linkGroup, index) => (
                      <Fragment key={"favourites-link-card-" + index}>
                        {linkGroup.linkGroupId && favouritesMap[linkGroup.linkGroupId] ?
                          <Grid
                            item
                            key={linkGroup.linkGroupId}
                            xl={2.4}
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                          >
                            <LinkCard favourite linkGroup={linkGroup} isFavourite={favouritesMap[linkGroup.linkGroupId]} tags={groupLinksTagsMap[linkGroup.linkGroupId] ? groupLinksTagsMap[linkGroup.linkGroupId].map((e) => {
                              return tagsMap[e];
                            }) : []} functions={functions} />

                          </Grid>
                          : ""}
                      </Fragment>
                    ))
                  }
                  </>
                  :
                  <Grid item xs={12}>
                    <EmptyText text={isLoadingLinks ? APP_STORE_INFO.LOAD_DATA_MESSAGE : APP_STORE_INFO.NO_APPS_TO_SHOW_MESSAGE} />
                  </Grid>}
              </Grid>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pt: 3
              }}
            >
              {/* <Pagination
              color="primary"
              count={3}
              size="small"
            /> */}
            </Box>
          </Container>
        </Box>
        <ImagePreview imagePreview={imagePreview} handleImagePreview={handleImagePreview} />
        <AddNewLinkGroup isOpen={isOpenAddNewLinkGroup} handleOpen={handleAddNewLinkGroup} getLinkGroups={getLinkGroups} handleSnackbar={props.handleSnackbar} handleImagePreview={handleImagePreview} />
        <UpdateLinkGroup isOpen={isOpenUpdateGroupLink} handleOpen={handleUpdateGroupLink} getLinkGroups={getLinkGroups} data={groupLinkId && groupLinksMap[groupLinkId] ? groupLinksMap[groupLinkId] : []}
          tags={groupLinksTagsMap[groupLinkId] ? groupLinksTagsMap[groupLinkId].map((e) => { return tagsMap[e] }) : []} roles={groupLinksRolesMap[groupLinkId] ? groupLinksRolesMap[groupLinkId].map((e) => { return rolesMap[e] }) : []} handleImagePreview={handleImagePreview}
          handleSnackbar={props.handleSnackbar} />
        <ViewLinkGroup isOpen={isOpenViewGroupLink} handleOpen={handleViewGroupLink} getLinkGroups={getLinkGroups} data={groupLinkId && groupLinksMap[groupLinkId] ? groupLinksMap[groupLinkId] : []}
          tags={groupLinksTagsMap[groupLinkId] ? groupLinksTagsMap[groupLinkId].map((e) => { return tagsMap[e] }) : []} roles={groupLinksRolesMap[groupLinkId] ? groupLinksRolesMap[groupLinkId].map((e) => { return rolesMap[e] }) : []} handleImagePreview={handleImagePreview} />
        <ScrollTop {...props}>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <PlusIcon />
          </Fab>
        </ScrollTop>
      </LinkGroupContext.Provider>
    </Fragment>
  )
};

export default Favourites;
