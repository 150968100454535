import { useEffect } from 'react';
import { Button, Card, CardMedia } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle as MuiDialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, data, ...other } = props;
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            <Grid container direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Grid item xs>
                    <Typography variant="h4">{children}</Typography>
                </Grid>
                <Grid item>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </Grid>
            </Grid>
        </MuiDialogTitle>
    );
});

export default function ImagePreview(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleClose = () => {
        props.handleImagePreview(null);
    };

    useEffect(() => {

    }, [props.imagePreview])

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth={"lg"}
            fullWidth
            open={Boolean(props.imagePreview)}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
                {props.imagePreview ? props.imagePreview.title : ""}
            </DialogTitle>
            <DialogContent>
                <Card>
                    {props.imagePreview && props.imagePreview.url ?
                        <CardMedia
                            component="img"
                            sx={{
                                backgroundAttachment: 'fixed',
                                backgroundSize: '100%',
                            }}
                            image={props.imagePreview.url}
                            title={props.imagePreview.title} /> :
                        null
                    }
                </Card>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" autoFocus onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
