import { useState, useEffect, useCallback } from 'react';
import useHttp from '../utils/http';
import { Route, Switch, Redirect } from "react-router-dom";
import { experimentalStyled } from '@material-ui/core';
import MainNavbar from './MainNavbar';
import SideBar from './Sidebar';
import LinkGroupList from '../pages/LinkGroupList';
import Favourites from '../pages/Favourites';
import NotFound from '../pages/NotFound';
import { APP_CONFIG } from '../config.js';
import Snackbars from './ui/Snackbars';
import { setUserAccessMap } from '../utils/oauth';
import SettingsPage from '../pages/SettingsPage';
import ErrorDialog from './dialog/ErrorDialog';
import UserContext from '../context/user-context';

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const MainLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  })
);

const MainLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const MainLayout = ({ page }) => {
  const queryString = require('query-string');
  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();
  const [appInitialized, setAppInitialized] = useState(false);
  const [errorDialog, setErrorDialog] = useState(null);
  const [snackbar, setSnackbar] = useState(null);

  const loadData = useCallback(() => {
    sendRequest(APP_CONFIG.APIS.INITIALIZE_APP, 'GET', null, APP_CONFIG.APIS.INITIALIZE_APP);
  }, [sendRequest]);

  const handleErrorDialog = (errorContent) => {
    setErrorDialog(errorContent ? errorContent : null);

  };

  const handleSnackbar = (message, severity) => {
    if (message) {
      setSnackbar({ message, severity });
    } else {
      setSnackbar(null);
    }
  };

  const handleSetIntializedApp = () => {
    setAppInitialized(true);
  };

  useEffect(() => {
    switch (reqExtra) {
      case APP_CONFIG.APIS.INITIALIZE_APP:
        if (data) {
          if (data.rolesAccessMap) {
            setUserAccessMap(data.rolesAccessMap, handleSetIntializedApp);
          }
        } else if (error) {
          handleErrorDialog({ message: "Failed to initialize application! Cannot reach services." });
          handleSnackbar("App initialization failed", "error");
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  useEffect(() => {
    loadData();
  }, [loadData])

  return (
    <UserContext.Consumer>
      {context => (
        <MainLayoutRoot>
          <MainNavbar />
          <SideBar page={page} />
          <MainLayoutWrapper>
            <MainLayoutContainer>
              {appInitialized && <MainLayoutContent>
                <Switch>
                  <Route exact path={APP_CONFIG.PAGES.HOME} render={({ match, location, history }) => {
                    return (
                      <>
                        <LinkGroupList view={location.pathname} handleSnackbar={handleSnackbar} />
                      </>
                    );
                  }} />
                  <Route exact path={APP_CONFIG.PAGES.FAVOURITES} render={({ match, location, history }) => {
                    return (
                      <>
                        <Favourites view={location.pathname} handleSnackbar={handleSnackbar} />
                      </>
                    );
                  }} />
                  <Route exact path={APP_CONFIG.PAGES.SETTINGS} render={({ match, location, history }) => {
                    let queryParams = queryString.parse(location.search);
                    // ?view=
                    let viewQueryParam = queryParams.view;
                    let view = "1";
                    if (viewQueryParam) {
                      switch (viewQueryParam) {
                        case APP_CONFIG.QUERY_VALUES.TAGS:
                          view = "2";
                          break;
                        case APP_CONFIG.QUERY_VALUES.ROLES:
                          view = "3";
                          break;
                        default:
                          view = "1";
                          break;
                      }
                    }
                    return (
                      <>
                        <SettingsPage view={view} handleSnackbar={handleSnackbar} />
                      </>
                    );
                  }} />
                  <Redirect exact from={APP_CONFIG.PAGES.APP} to={APP_CONFIG.PAGES.HOME} />
                  <Route component={NotFound} />
                </Switch>
              </MainLayoutContent>}
              {errorDialog && <ErrorDialog open={Boolean(errorDialog)} message={errorDialog?.message} handleOpen={handleErrorDialog} />}
              <Snackbars snackbar={snackbar} handleSnackbar={handleSnackbar} />
            </MainLayoutContainer>
          </MainLayoutWrapper>
        </MainLayoutRoot>
      )
      }
    </UserContext.Consumer >
  );
};

export default MainLayout;
