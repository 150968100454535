import { useState, useEffect, useRef } from "react";
import { Chip, Box, Typography, Tooltip } from "@material-ui/core";
import { tooltipClasses } from '@material-ui/core/Tooltip';
import { styled } from '@material-ui/core/styles';


const ChipTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    boxShadow: '0px 0px 8px #b8bbcc',
  },
}));

const TagsDisplay = (props) => {
  const [element, setElement] = useState(null);
  const [tags, setTags] = useState([]);
  const [renderedTags, setRenderedTags] = useState([]);
  const [tagDisplayWidth, setTagDisplayWidth] = useState(0);

  const ref = useRef(null);

  const handleSearchTags = (tags) => () => {
    props.handleSearchTags && props.handleSearchTags(tags);
  };

  useEffect(() => {
    setElement(props.element);
    setTags(props.tags);
  }, [props.element, props.tags]);

  useEffect(() => {
    const characterWidth = 17;
    const tags = props.tags;
    if (tags.length) {
      if (tags.length === 1) {
        setRenderedTags(tags)
      } else if (tags.length === 2) {
        if (tags[0].name.length * characterWidth + tags[1].name.length * characterWidth < tagDisplayWidth) {
          setRenderedTags(tags.slice(0, 2));
        } else {
          setRenderedTags(tags.slice(0, 1));
        }
      } else {
        if (tags[0].name.length * characterWidth + tags[1].name.length * characterWidth +
          tags[2].name.length * characterWidth < tagDisplayWidth) {
          setRenderedTags(tags.slice(0, 3));
        } else if (tags[0].name.length * characterWidth + tags[1].name.length * characterWidth < tagDisplayWidth) {
          setRenderedTags(tags.slice(0, 2));
        } else {
          setRenderedTags(tags.slice(0, 1));
        }
      }
    }
  }, [props.tags, tagDisplayWidth]);

  useEffect(() => {
    setTagDisplayWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);


  return (
    <>
      {element && <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          width: '100%',
        }}
        ref={ref}
      >
        <Box
          sx={{
            maxWidth: 260,
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {
            renderedTags.map(tag => {
              const chip = tag ? tag : { id: "id", name: "", color: null };
              return (
                <Chip
                  key={chip.id + "-" + element.linkGroupId}
                  variant="outlined"
                  style={{
                    margin: '0px 2px',
                    height: 24,
                    fontSize: '0.8rem',
                    color: (chip.color),
                    borderColor: (chip.color),
                    borderRadius: '4px'
                  }}
                  size="small" color="primary"
                  label={chip.name}
                  onClick={tag && handleSearchTags([chip])}
                />)
            })
          }
        </Box>
        {tags.length - renderedTags.length > 0 &&
          <ChipTooltip
            title={
              tags.map(tag => {
                const chip = tag ? tag : { id: "id", name: "", color: null };
                return (
                  <Chip
                    key={chip.id + "-" + element.linkGroupId}
                    variant="outlined"
                    style={{
                      margin: '2px 2px',
                      height: 24,
                      fontSize: '0.8rem',
                      color: (chip.color),
                      borderColor: (chip.color),
                      borderRadius: '4px'
                    }} size="small"
                    color="primary"
                    label={chip.name}
                    onClick={tag && handleSearchTags([chip])}
                  />)
              })
            }
            arrow
          >
            <Box sx={{ px: 1 }}>
              <Typography sx={{ fontSize: "13px", fontWeight: "600", color: '#828282' }}>
                {`+${tags.length - renderedTags.length}`}
              </Typography>
            </Box>
          </ChipTooltip>
        }
      </Box>
      }
    </>
  );
};

export default TagsDisplay;