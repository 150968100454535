import { useState, useEffect } from 'react';
import { Divider, IconButton, InputBase, Paper } from '@material-ui/core';
import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, Search as SearchIcon, SortByAlpha as SortByAlphaIcon } from '@material-ui/icons';

export default function SearchBar(props) {
    const [search, setSearch] = useState("");
    const [isAlphabetical, setIsAlphabetical] = useState(true);

    const handleSearch = (event) => {
        let value = event.target.value;
        props.handleSearch(value);
    };

    const handleAlphabetical = () => {
        props.handleAlphabetical(!isAlphabetical);
    };

    useEffect(() => {
        setSearch(props.value);
        setIsAlphabetical(props.isAlphabetical)
    }, [props.value, props.isAlphabetical])

    return (
        <Paper
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '60%' }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                value={search}
                onChange={handleSearch}
            />
            <IconButton sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton onClick={handleAlphabetical} color="primary" sx={{ p: '5px', }} aria-label="directions">
                <SortByAlphaIcon />
            </IconButton>
            {isAlphabetical ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />}

        </Paper>
    );
}
