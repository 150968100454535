import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import useHttp from '../../../utils/http';
import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import {
    Eye as VisibilityIcon,
    Trash2 as DeleteIcon,
    Upload as UploadIcon,
} from 'react-feather';
import PreviewNotAvailable from '../../ui/PreviewNotAvailable';
import ImagePreview from '../ImagePreview';
import { APP_CONFIG } from '../../../config';

const useStyles = makeStyles((theme) => ({
    preview: {
        margin: 'auto',
        display: 'block',
        objectFit: 'cover',
        width: '100%',
        maxHeight: 250,
    },
    imageUpload: {
        padding: 0,
    },
    image: {
        minHeight: 200,
        border: '1px solid #ccc',
        borderRadius: 4,
    },
}));

const Item = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ImageUploadAndPreview = (props) => {
    const classes = useStyles();
    const { isLoading, data, error, handleFileRequest, reqExtra, isOpen } = useHttp();
    const [url, setUrl] = useState(null);
    const [imagePreview, setImagePreview] = useState(null)

    const handleOpenImagePreview = () => () => {
        handleImagePreview(true)
    };

    const handleImagePreview = (isOpen) => {
        setImagePreview((isOpen ? {
            title: "Preview",
            url: url,
        } : null))
    };

    const getImage = (fileName) => {
        if (fileName) {
            handleFileRequest(`${APP_CONFIG.APIS.IMAGE_URL}/${fileName}`, 'GET', null, "IMAGE_URL");
        }
    };

    useEffect(() => {
        switch (reqExtra) {
            case "IMAGE_URL":
                if (data) {
                    setUrl(data);
                }
                break;
            default:
                break;
        }
    }, [data, reqExtra, isOpen, isLoading, error]);

    useEffect(() => { getImage(props.imageName) }, [props.compressedImage, props.imageName, props.imageProgress]);
    useEffect(() => { }, [props.readOnly])

    return (
        <>
            <Grid container direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.image}>
                {props.imageName || props.compressedImage ?
                    <Grid item container>
                        <Grid item xs={12}>
                            <img className={classes.preview} alt={"preview"} src={url ? url : (props.imageProgress === 0 ? props.compressedImage : null)} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center !important', }}>
                            <Stack direction="row" justifyContent="center"
                                alignItems="center" spacing={1}>
                                <span>
                                    <Tooltip title="View">
                                        <IconButton aria-label="view"
                                            onClick={handleOpenImagePreview()}
                                            size="small">
                                            <VisibilityIcon size="20px" />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                                {!props.readOnly ? <span>
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="delete"
                                            onClick={props.handleImageDelete}
                                            size="small">
                                            <DeleteIcon size="20px" />
                                        </IconButton>
                                    </Tooltip>
                                </span> : null}
                            </Stack>

                        </Grid>
                    </Grid> : props.readOnly ?
                        <PreviewNotAvailable />
                        :
                        <Grid item className={classes.imageUpload}>
                            {props.imageProgress === 0 ?
                                <Stack spacing={1}>
                                    <Item><input
                                        accept="image/*"
                                        style={{
                                            display: 'none',
                                        }}
                                        id="contained-button-file"
                                        multiple
                                        onChange={props.handleImageUpload}
                                        type="file"
                                    />
                                        <label htmlFor="contained-button-file">
                                            <Button variant="contained" color="primary" component="span" startIcon={<UploadIcon />}>
                                                Upload Preview
                                            </Button>
                                        </label></Item>
                                    <Item><Typography variant="caption">
                                        All uploaded images will be compressed.
                                    </Typography><br />
                                        <Typography variant="caption">
                                            Recommended image file size is below 10 MB.
                                        </Typography><br />
                                        <Typography variant="caption">
                                            Image should be in 'Landscape' orientation.
                                        </Typography>
                                    </Item>
                                </Stack>
                                :
                                <CircularProgress variant="determinate" value={props.imageProgress} />}
                        </Grid>
                }
            </Grid>
            {imagePreview && <ImagePreview imagePreview={imagePreview} handleImagePreview={handleImagePreview} />}
        </>
    );
}

export default ImageUploadAndPreview;