import React, { useState, useEffect, useReducer } from 'react';
import imageCompression from 'browser-image-compression';
import { withStyles } from '@material-ui/styles';
import useHttp from '../../utils/http';
import {
    Button,
    Dialog,
    Grid,
    IconButton,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    Typography,
    Stack,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import LoadingButton from '@material-ui/lab/LoadingButton';
import Content from './content/UpdateLinkGroupContent';
import { getDateForDB } from '../../utils/formatting';
import { APP_CONFIG, VALIDATION } from '../../config.js';
import { getDatesForPeriod } from '../../utils/utils';
import { applyLengthValidation, validateURL } from '../../utils/validate';
import UserChip from '../ui/UserChip';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        color: theme.palette.grey[500],
    },
});

const ACTIONS = {
    SET_DATA: 'SET_DATA',
    SET_TITLE: 'SET_TITLE',
    SET_DESCRIPTION: 'SET_DESCRIPTION',
    SET_LINKS: 'SET_LINKS',
    SET_IMAGE: 'SET_IMAGE',
    DELETE_IMAGE: 'DELETE_IMAGE',
    SET_IMAGE_UPLOAD_PROGRESS: 'SET_IMAGE_UPLOAD_PROGRESS',
    SET_TAGS: 'SET_TAGS',
    SET_ROLES: 'SET_ROLES',
    SET_IS_RECURRING: 'SET_IS_RECURRING',
    SET_IS_VERIFIED: 'SET_IS_VERIFIED',
    HANDLE_RESET: 'HANDLE_RESET',
}

const getInitialLinkElement = () => {
    return { id: null, link: "", validFrom: new Date(), validTo: new Date(), name: null, period: null }
};

const linksReducer = (curLinkState, action) => {
    switch (action.type) {
        case ACTIONS.SET_DATA:
            return {
                ...curLinkState, linkGroupId: action.linkGroupId, title: action.title, description: action.description, imageName: action.imageName, links: action.links,
                isRecurring: action.isRecurring, isVerified: action.isVerified, addedBy: action.addedBy
            }
        case ACTIONS.SET_TITLE:
            return { ...curLinkState, title: action.title }
        case ACTIONS.SET_DESCRIPTION:
            return { ...curLinkState, description: action.description }
        case ACTIONS.SET_LINKS:
            return { ...curLinkState, links: action.links }
        case ACTIONS.SET_IMAGE:
            return { ...curLinkState, imageName: action.imageName, compressedImage: action.compressedImage }
        case ACTIONS.DELETE_IMAGE:
            return { ...curLinkState, imageName: "", compressedImage: null }
        case ACTIONS.SET_TAGS:
            return { ...curLinkState, tags: action.tags, }
        case ACTIONS.SET_ROLES:
            return { ...curLinkState, roles: action.roles, }
        case ACTIONS.SET_IS_RECURRING:
            return { ...curLinkState, isRecurring: action.isRecurring }
        case ACTIONS.SET_IS_VERIFIED:
            return { ...curLinkState, isVerified: action.isVerified }
        //TODO RESET
        case ACTIONS.HANDLE_RESET:
            return { ...curLinkState, title: "", description: "", links: [getInitialLinkElement], imageName: "", compressedImage: null, isRecurring: false, };
        default:
            throw new Error('Should not get here');
    }
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, data, ...other } = props;
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            <Grid container direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Grid item xs>
                    <Typography variant="h4">{children}</Typography>
                </Grid>
                <Grid item>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </Grid>
            </Grid>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);



const CustomizedDialogs = (props) => {
    const [open, setOpen] = useState(false);
    const [imageProgress, setImageProgress] = React.useState(0);
    const [{ linkGroupId, title, description, links, imageName, compressedImage, isRecurring, isVerified, tags, roles, addedBy }, dispatchLinks] = useReducer(linksReducer,
        { linkGroupId: null, title: "", description: "", links: [getInitialLinkElement()], imageName: "", compressedImage: null, isRecurring: false, isVerified: false, tags: [], roles: [], addedBy: "" });
    const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();

    const isFormValid = () => {
        if (!title) {
            return false;
        }

        return links.every((e) => e.link && validateURL(e.link) && (validateDates(e.validFrom, e.validTo)));
    }

    const isImageLoading = () => {
        return isLoading || (imageProgress < 100 && imageProgress > 0);
    };

    const handleTitle = (event) => {
        dispatchLinks({ type: ACTIONS.SET_TITLE, title: applyLengthValidation(event.target.value, VALIDATION.LINK_GROUP_TITLE_LENGTH) });
    }

    const handleDescription = (event) => {
        dispatchLinks({ type: ACTIONS.SET_DESCRIPTION, description: applyLengthValidation(event.target.value, VALIDATION.LINK_GROUP_DESCRIPTION_LENGTH) });
    }

    const addNewLinkElement = () => {
        dispatchLinks({ type: ACTIONS.SET_LINKS, links: [getInitialLinkElement()] });
    }

    const deleteLinkElement = (index) => {
        if (links[index]) {
            links.splice(index, 1);
            dispatchLinks({ type: ACTIONS.SET_LINKS, links: links });
        }
    }

    const handleLinks = (event, index) => {
        if (links[index]) {
            var linkElement = links[index];
            linkElement.link = applyLengthValidation(event.target.value, VALIDATION.LINK_LENGTH);
            links.splice(index, 1, linkElement);
            dispatchLinks({ type: ACTIONS.SET_LINKS, links: links });
        }
    }

    const handleTags = (tags) => {
        dispatchLinks({ type: ACTIONS.SET_TAGS, tags: tags });
    }

    const handleRoles = (roles) => {
        dispatchLinks({ type: ACTIONS.SET_ROLES, roles: roles });
    }

    const validateDates = (validFrom, validTo) => {
        let validFromDate = new Date(validFrom);
        let validToDate = new Date(validTo);
        if ((validFrom === null) || (validTo === null)) {
            return true;
        }
        if (validFromDate instanceof Date && validToDate instanceof Date) {
            return (getDateForDB(validFromDate) === getDateForDB(validToDate) || validFromDate.getTime() <= validToDate.getTime());
        }
        return false;
    }

    //Used for Datepicker as no min date attribute was available
    const disablePastDates = (date, index) => {
        if (links[index] && links[index].validFrom instanceof Date) {
            return !(getDateForDB(date) === getDateForDB(links[index].validFrom)) && (date.getTime() < links[index].validFrom.getTime());
        }
        return false;
    }

    const handleVersionName = (name, index) => {
        if (links[index]) {
            var linkElement = links[index];
            linkElement.name = applyLengthValidation(name, VALIDATION.VERSION_LENGTH);
            links.splice(index, 1, linkElement);
            dispatchLinks({ type: ACTIONS.SET_LINKS, links: links });
        }
    }

    const handleValidFrom = (date, index) => {
        if (links[index]) {
            var linkElement = links[index];
            if (date instanceof Date && linkElement.validTo instanceof Date && date.getTime() > linkElement.validTo.getTime()) {
                handleValidTo(date, index);
            }
            linkElement.validFrom = date;
            links.splice(index, 1, linkElement);
            dispatchLinks({ type: ACTIONS.SET_LINKS, links: links });
        }
    }

    const handleValidTo = (date, index) => {
        if (links[index]) {
            var linkElement = links[index];
            linkElement.validTo = date;
            links.splice(index, 1, linkElement);
            dispatchLinks({ type: ACTIONS.SET_LINKS, links: links });
        }
    }

    const handlePeriod = (period, isDynamic, index) => {
        if (links[index]) {
            var linkElement = links[index];
            linkElement.period = period;
            linkElement.isDynamic = isDynamic;
            //Only edit when custom(null) is not chosen
            if (period) {
                var periodDates = getDatesForPeriod(period);
                linkElement.validFrom = periodDates.firstDay;
                linkElement.validTo = periodDates.lastDay;
            }
            links.splice(index, 1, linkElement);
            dispatchLinks({ type: ACTIONS.SET_LINKS, links: links });
        }
    }

    const handleIsVerified = (event) => {
        dispatchLinks({ type: ACTIONS.SET_IS_VERIFIED, isVerified: event.target.checked });
    }

    const handleIsRecurring = (isRecurring) => {
        dispatchLinks({ type: ACTIONS.SET_IS_RECURRING, isRecurring: isRecurring });
    }

    async function handleImageUpload(event) {

        const imageFile = event.target.files[0];
        const options = {
            maxSizeMB: 4,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: "jpeg",
            onProgress: (progress) => {
                setImageProgress(progress <= 25 ? 25 : progress <= 50 ? 50 : progress);
                if (progress === 100) {
                    setTimeout(() => {
                        setImageProgress(0);
                    }, 1000)
                }
            }
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            dispatchLinks({ type: ACTIONS.SET_IMAGE, imageName: imageName, compressedImage: URL.createObjectURL(compressedFile) });
            await uploadToServer(compressedFile);
        } catch (error) {
            console.error(error);
        }

    }

    async function uploadToServer(compressedFile) {
        const reader = new FileReader()
        reader.readAsDataURL(compressedFile);
        reader.onload = () => {
            const encodedFile = btoa(reader.result);
            const payload = {
                encodedFile: encodedFile
            }
            sendRequest(APP_CONFIG.APIS.FILE_UPLOAD, 'POST', JSON.stringify(payload), APP_CONFIG.APIS.FILE_UPLOAD);
        }
    }

    function handleImageDelete() {
        dispatchLinks({ type: ACTIONS.DELETE_IMAGE, });
    }

    const handleAddNewLink = () => {
        links.push(getInitialLinkElement());
        dispatchLinks({ type: ACTIONS.SET_LINKS, links: links });
    }

    async function handleUpdate() {
        if (isImageLoading() || isFormValid()) {
            var payload = {
                "linkGroupId": linkGroupId,
                "title": title,
                "description": description,
                "links": (isRecurring ? links.map((e) => {
                    var linkElement = {};
                    linkElement['id'] = e.id ? e.id : null;
                    linkElement['link'] = e.link;
                    linkElement['linkGroupId'] = linkGroupId;
                    linkElement['versionName'] = e.name;
                    linkElement['period'] = (e.isDynamic ? e.period : null);
                    linkElement['isDynamic'] = e.isDynamic;
                    linkElement['validFrom'] = getDateForDB(e.validFrom);
                    linkElement['validTo'] = getDateForDB(e.validTo);
                    return linkElement;
                }) : [{
                    'id': links[0].id ? links[0].id : null,
                    'linkGroupId': linkGroupId,
                    'versionName': null,
                    'period': (links[0].isDynamic ? links[0].period : null),
                    'isDynamic': links[0].isDynamic,
                    'link': links[0].link,
                    'validFrom': getDateForDB(links[0].validFrom),
                    'validTo': getDateForDB(links[0].validTo),
                }]),
                "preview": imageName,
                "tagIds": tags.map(e => e?.id),
                "roles": roles.map(e => e?.id).join(','),
                "isRecurring": Boolean(isRecurring),
                "isVerified": Boolean(isVerified)
            };

            sendRequest(APP_CONFIG.APIS.UPDATE_LINK_GROUP, 'PATCH', JSON.stringify(payload), APP_CONFIG.APIS.UPDATE_LINK_GROUP);
        }
    }

    const handleClose = () => {
        dispatchLinks({ type: ACTIONS.HANDLE_RESET });
        props.handleOpen(false);
    };

    useEffect(() => {
        switch (reqExtra) {
            case APP_CONFIG.APIS.FILE_UPLOAD:
                if (!error && data?.fileName) {
                    var imageName = data.fileName;
                    props.handleSnackbar('Image uploaded!', 'success');
                    dispatchLinks({ type: ACTIONS.SET_IMAGE, imageName: imageName, compressedImage: compressedImage });
                } else if (error) {
                    props.handleSnackbar('Image failed to upload!', 'error');
                    dispatchLinks({ type: ACTIONS.SET_IMAGE, imageName: "", compressedImage: null });
                }
                break;
            case APP_CONFIG.APIS.FILE_DELETE:
                //TODO
                break;
            case APP_CONFIG.APIS.UPDATE_LINK_GROUP:
                if (!error && data) {
                    if (data.message) {
                        props.handleSnackbar(data.message, "success");
                    }
                    handleClose();
                    props.getLinkGroups();

                } else if (error) {
                    props.handleSnackbar("Failed to update!", "error");
                }
                break;
            default:
                break;
        }
    }, [data, reqExtra, isOpen, isLoading, error]);

    useEffect(() => {
        setOpen(props.isOpen);
        if (props.data) {
            var linkGroupId = props.data.linkGroupId;
            var title = props.data.title ? props.data.title : "";
            var description = props.data.description ? props.data.description : "";
            var imageName = props.data.preview ? props.data.preview : "";
            var addedBy = props.data.addedBy ? props.data.addedBy : "";
            var links = props.data.links ?
                props.data.links.map(link => {
                    var dates = getDatesForPeriod(link.period);
                    return {
                        id: link.id, link: link.link,
                        validFrom: (dates.firstDay ? dates.firstDay : link.validFrom),
                        validTo: (dates.lastDay ? dates.lastDay : link.validTo),
                        name: link.versionName, period: link.period, isDynamic: Boolean(link.period)
                    }
                })
                : [getInitialLinkElement()];
            var isRecurring = props.data.isRecurring ? props.data.isRecurring : false;
            var isVerified = props.data.isVerified ? props.data.isVerified : false;
            dispatchLinks({
                type: ACTIONS.SET_DATA, linkGroupId: linkGroupId, title: title, description: description, imageName: imageName, links: links,
                isRecurring: isRecurring, isVerified: isVerified, addedBy: addedBy
            });
        }
    }, [props.isOpen, props.data]);

    useEffect(() => {
        dispatchLinks({ type: ACTIONS.SET_TAGS, tags: props.tags });
    }, [props.tags]);

    useEffect(() => {
        dispatchLinks({ type: ACTIONS.SET_ROLES, roles: props.roles });
    }, [props.roles]);

    const functions = {
        handleImageUpload: handleImageUpload,
        handleImageDelete: handleImageDelete,
        handleTitle: handleTitle,
        handleDescription: handleDescription,
        handleLinks: handleLinks,
        addNewLinkElement: addNewLinkElement,
        handleValidFrom: handleValidFrom,
        handleValidTo: handleValidTo,
        handlePeriod: handlePeriod,
        handleVersionName: handleVersionName,
        disablePastDates: disablePastDates,
        handleIsRecurring: handleIsRecurring,
        handleIsVerified: handleIsVerified,
        handleAddNewLink: handleAddNewLink,
        deleteLinkElement: deleteLinkElement,
        handleTags: handleTags,
        handleRoles: handleRoles,
    };

    return (
        <Dialog aria-labelledby="add-new-link-group" open={open}
            fullWidth maxWidth={"xl"}>
            <DialogTitle id="add-new-link-group" onClose={handleClose}>
                Edit
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    {addedBy && <span><Typography variant="h6">{"Created by: "}<UserChip owner={addedBy} /></Typography></span>}
                    {linkGroupId &&
                        <span><Content
                            title={title}
                            imageName={imageName}
                            compressedImage={compressedImage}
                            description={description}
                            links={links}
                            isRecurring={isRecurring}
                            isVerified={isVerified}
                            tags={tags}
                            roles={roles}
                            imageProgress={imageProgress}
                            functions={functions}
                        /></span>}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="outlined" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <LoadingButton loading={isImageLoading()} disabled={isImageLoading() || !isFormValid()} variant="contained" onClick={handleUpdate} color="secondary" >
                    Done
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CustomizedDialogs;