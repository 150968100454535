import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

export default function ErrorDialog(props) {

    const handleClose = () => {
        props.handleOpen();
    };

    useEffect(() => {

    }, [props.open, props.message])

    return (
        <Dialog
        fullWidth
            open={props.open}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
        >
            <DialogTitle id="error-dialog-title">
                <Typography variant="h4">
                {"Error"}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="error-dialog-description">
                    {props.message ? props.message : "The application ran into an error!"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={"contained"} color={"secondary"} autoFocus>
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    );
}
