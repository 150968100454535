import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    CssBaseline,
    Grid,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';

import ChipSelector from '../../ui/ChipSelector';
import LinksListItem from '../components/LinksListItem';
import { VALIDATION } from '../../../config';
import { getUserRoles } from '../../../utils/oauth';
import { validateURL } from '../../../utils/validate';


const useStyles = makeStyles((theme) => ({
    preview: {
        margin: 'auto',
        display: 'block',
        objectFit: 'cover',
        width: '100%',
        maxHeight: 250,
    },
    imageUpload: {
        padding: 0,
    },
    alert: {
        padding: '2px 6px !important',
    },
    image: {
        minHeight: 100,
        border: '1px solid #ccc',
        borderRadius: 4,
    },
    chip: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        // minHeight: 50,
        maxHeight: 200,
        overflow: 'scroll',
        padding: 10,
    },
    linkTextField: {
        paddingRight: '0px !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
    },
    linkListItem: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
    },
    linkAddButtonContainer: {
        width: '100%',
        textAlign: 'center',
    },
}));

const LinkGroupContent = (props) => {
    const classes = useStyles();
    const functions = props.functions;
    const [roleOptions, setRoleOptions] = useState([]);

    useEffect(() => {
        var userRoles = getUserRoles();
        var rolesFetched = Object.values(props.rolesMap).map(e => { return e?.name; });
        setRoleOptions(Object.values(props.rolesMap).filter(e => {
            return rolesFetched.includes(e?.name);
        }
        ));
    }, [props.rolesMap]);
    useEffect(() => { }, [props.title, props.description, props.isVerified, props.tagsMap, props.tags, props.roles]);
    useEffect(() => { }, [props.readOnly]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={7} sx={{ pb: 1 }}>
                <TextField
                    fullWidth
                    label="Title"
                    name="Link title"
                    onChange={functions.handleTitle}
                    required={!props.readOnly}
                    value={props.title}
                    autoComplete="off"
                    variant="outlined"
                    helperText={!props.readOnly && props?.title.length >= VALIDATION.LINK_GROUP_TITLE_LENGTH ? "Maximum character limit reached" : null}
                    InputProps={{
                        readOnly: props.readOnly,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="description-textarea"
                    label="Description"
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    onChange={functions.handleDescription}
                    value={props.description}
                    helperText={!props.readOnly && props?.description.length >= VALIDATION.LINK_GROUP_DESCRIPTION_LENGTH ? "Maximum character limit reached" : null}
                    InputProps={{
                        readOnly: props.readOnly,
                    }}
                />
            </Grid>
            <Grid item xs={7.4}>
                {props.links.length ? [props.links[0]].map((link, index) => {
                    const isValid = validateURL(link.link);
                    return (
                        <LinksListItem key={"link-list-item-" + index} index={index} link={link} isValid={isValid} functions={functions} isRecurring={props.isRecurring} period={link.period} isDynamic={link.isDynamic} versionName={link.name} />
                    );
                }) : ""}

            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12} md={6} direction="column" justifyContent="flex-start">
                    <Typography variant="h4" gutterBottom>
                        Tags
                    </Typography>
                    <Paper style={{ maxHeight: 160, overflow: 'auto' }}>
                        <Grid item xs={12}>
                            <CssBaseline />
                            <ChipSelector id="tags-selector" options={Object.values(props.tagsMap)} value={props.tags} handleValue={functions.handleTags} placeholder={"Type tag(s) to add"} readOnly={props.readOnly} />
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>
                        Roles
                    </Typography>
                    <Paper style={{ maxHeight: 160, overflow: 'auto' }}><Grid item xs={12}>
                        <CssBaseline />
                        <ChipSelector id="roles-selector" options={roleOptions} value={props.roles} handleValue={functions.handleRoles} placeholder={"Type role(s) to restrict"} readOnly={props.readOnly} />
                    </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LinkGroupContent;