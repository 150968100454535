import { useEffect, useState } from 'react';
import useHttp from '../../utils/http';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';

import {
  Favorite,
  FavoriteBorder,
} from '@material-ui/icons';

import {
  Edit2 as EditIcon,
  Eye as VisibilityIcon,
  EyeOff as VisibilityOffIcon,
  MinusCircle as MinusCircleIcon,
  Trash as DeleteIcon,
  MoreHorizontal as MoreHorizontalIcon,
} from 'react-feather';

import { makeStyles, useTheme } from '@material-ui/styles';
import OptionMenu from '../ui/OptionMenu';
import { APP_CONFIG } from '../../config.js';
import { getOpenUrl } from '../../utils/utils';
import { DEFINED_ACCESS } from '../../utils/oauth';
import TagsDisplay from '../ui/TagsDisplay';
import ConfirmationDialog from '../dialog/ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  bottomFab: {
    position: 'absolute !important',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    textTransform: 'none !important',
  },
  topFab: {
    backgroundColor: `${theme.palette.success.main} !important`,
    position: 'absolute !important',
    top: theme.spacing(1),
    left: theme.spacing(1),
    width: "20px !important",
    height: "20px !important",
    textTransform: 'none !important',
  },
  badge: {
    top: '2px !important',
    right: '5px !important',
    border: `1px solid ${theme.palette.background.paper}`,
  },
  avatar: {
    backgroundColor: 'red',
  },
  favourite: {
    color: theme.palette.secondary.main,
  },
  linkCard: {
    boxShadow: "0px 1px 1px #CBCEDB !important",
    borderRadius: "8px !important"
  }
}));

const LinkCard = (props) => {
  const { functions, favourite } = props;
  const history = useHistory();
  const classes = useStyles();
  const { isLoading, data, error, sendRequest, handleFileRequest, reqExtra, isOpen } = useHttp();
  const theme = useTheme();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [linkMenuAnchorEl, setLinkMenuAnchorEl] = useState(null);
  const linkMenuOpen = Boolean(linkMenuAnchorEl);
  const [tags, setTags] = useState([]);
  const [element, setElement] = useState(props.linkGroup);
  const [url, setUrl] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(null);

  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleFavourite = () => (event) => {
    if (element.linkGroupId) {
      functions.handleFavourite && functions.handleFavourite(event, element.linkGroupId);
    }
  };

  const handleConfirmationDialog = (content) => {
    setConfirmationDialog(content);
  };

  const handleDeleteLinkGroup = (linkGroupId) => {
    linkGroupId && sendRequest(APP_CONFIG.APIS.DELETE_LINK_GROUP + "/" + linkGroupId, 'DELETE', null, APP_CONFIG.APIS.DELETE_LINK_GROUP);
  };

  const getImage = (fileName) => {
    if (fileName) {
      handleFileRequest(`${APP_CONFIG.APIS.IMAGE_URL}/${fileName}`, 'GET', null, "IMAGE_URL");
    } else {
      setUrl(null);
    }
  };

  const handleOpenView = () => {
    element.isRecurring ? history.push(APP_CONFIG.PAGES.RECURRING + "/" + element.linkGroupId + "?mode=" + APP_CONFIG.QUERY_VALUES.VIEW) : functions.handleViewGroupLink(true, element.linkGroupId);
  };

  const handleLinkMenuOpen = (event) => {
    event.stopPropagation();
    setLinkMenuAnchorEl(event.currentTarget);
  };

  const handleLinkMenuClose = (event) => {
    event.stopPropagation();
    setLinkMenuAnchorEl(null);
  };

  const optionsForNonFavourite = [
    {
      title: "View",
      icon: <VisibilityIcon size={16} />,
      action: () => {
        handleOpenView();
        handleClose();
      },
    },
    {
      title: "Edit",
      icon: <EditIcon size={16} />,
      action: () => {
        functions?.handleUpdateGroupLink(true, element.linkGroupId);
        handleClose();
      },
      access: DEFINED_ACCESS.EDIT,
      owner: element.addedBy
    },
    {
      title: "Delete",
      color: theme.palette.error.main,
      icon: <DeleteIcon size={16} />,
      action: () => {
        handleConfirmationDialog({
          title: "WARNING! You are about to delete the folowing link.",
          text: element.title,
          confirmAction: () => { handleDeleteLinkGroup(element.linkGroupId); }
        });
        handleClose();
      },
      access: DEFINED_ACCESS.ADMIN,
      owner: element.addedBy
    },
  ];

  const optionsForFavourite = [
    {
      title: "View",
      icon: <VisibilityIcon size={16} />,
      action: () => {
        functions?.handleViewGroupLink(true, element.linkGroupId);
        handleClose();
      },
    },
    {
      title: "Edit",
      icon: <EditIcon size={16} />,
      action: () => {
        functions?.handleUpdateGroupLink(true, element.linkGroupId);
        handleClose();
      },
      access: DEFINED_ACCESS.EDIT,
      owner: element.addedBy
    },
    {
      title: "Remove favourite",
      color: theme.palette.error.main,
      icon: <MinusCircleIcon size={16} />,
      action: () => {
        handleConfirmationDialog({
          title: "WARNING! You are about to remove this from your favourites.",
          text: element.title,
          confirmAction: () => { functions?.handleFavourite(element.linkGroupId); }
        });
        handleClose();
      },
    },
  ];

  const LinkOptions = (props) => {
    const limit = 20;
    return (
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            minWidth: 200,
            maxWidth: 340
          },
        }}
      >
        <Typography sx={{ p: 1 }} variant="caption">
          {(props.options.length >= limit ? "Top " + limit + " links created last" : props.options.length + " link" + (props.options.length === 1 ? "" : "s"))}
        </Typography>
        {/* Gets first 20 links */}
        {props.options.slice(0, limit).map((option, index) => (
          <span key={"link-options-" + index}>
            <Tooltip title={option.link} placement="right">
              <Link href={getOpenUrl(option.link)} target="_blank" rel="noreferrer" underline="none">
                <MenuItem key={uuid()} onClick={props.handleClose}>
                  <Typography sx={{ maxWidth: 300 }} variant="inherit" noWrap>
                    {option?.versionName?.trim() ? option.versionName : <em>(No version)</em>}
                  </Typography>
                </MenuItem>
              </Link>
            </Tooltip>
          </span>
        ))}
      </Menu>
    );
  };

  useEffect(() => {
    switch (reqExtra) {
      case "IMAGE_URL":
        if (data) {
          setUrl(data);
        }
        break;
      case APP_CONFIG.APIS.DELETE_LINK_GROUP:
        if (!error && data) {
          if (data?.message) {
            functions?.handleSnackbar(data.message, "success");
          }
          functions?.load();
        } else if (error) {
          functions?.handleSnackbar("Failed to delete link!", "error");
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  useEffect(() => {
    if (props.tags) {
      setTags(props.tags.filter((e) => {
        return e;
      }));
    }
  }, [props.tags]);

  useEffect(() => {
    if (props.linkGroup) {
      setElement(props.linkGroup);
      getImage(props.linkGroup?.preview);
    }
  }, [props.linkGroup]);

  return (
    <>
      {element &&
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
          }}
          variant="outlined"
          className={classes.linkCard}
        >
          <CardContent
            sx={{ p: 0 }}>
            <CardActionArea >
              <Link href={getOpenUrl(element.links.length && element.links[0].link)} underline="none" target="_blank" rel="noreferrer">
                <Grid container spacing={2}
                  sx={{
                    py: 2,
                  }}
                >
                  <Grid item xs={4}>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {url ?
                        <CardMedia
                          sx={{
                            height: 65,
                            width: 65
                          }}
                          component="img"
                          src={url}
                          title={element.title}
                        />
                        :
                        <div style={{
                          height: 65,
                          width: 65
                        }}>
                          {isLoading ?
                            <Stack
                              sx={{ height: '100%' }}
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <CircularProgress color="secondary" />
                            </Stack>
                            :
                            <>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '50%',
                                  pb: 3,
                                }}
                              >
                                <Avatar
                                  alt="visibility-icon"
                                  variant="square"
                                >
                                  <VisibilityOffIcon />
                                </Avatar>
                              </Box>
                              <Typography
                                sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', pt: 1, }}
                                align="center"
                                color="textPrimary"
                                fontSize={10}
                              >
                                Preview not available
                              </Typography>
                            </>
                          }

                        </div>
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={5.8}></Grid>
                  <Grid item xs={2.2}>
                    {favourite ? null : <Grid item>
                      <Checkbox checked={Boolean(props.isFavourite)} onChange={handleFavourite()} style={{ p: 8, color: '#D0D0D0' }} size="small" inputProps={{ 'aria-label': 'Favourite' }} icon={<FavoriteBorder fontSize="small" />} checkedIcon={<Favorite className={classes.favourite} />} />
                    </Grid>}
                  </Grid>
                </Grid>
                <Grid container spacing={1}
                  sx={{
                    px: 2,
                  }}
                >
                  <Grid item xs={12} zeroMinWidth sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip title={element.title}>
                      <Typography
                        variant="subtitle1"
                        display="inline"
                        noWrap
                      >
                        {element.title}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', height: '45px' }}>
                    <Typography
                      variant="body2"
                      display="inline"
                    >
                      {element.description}
                    </Typography>
                  </Grid>
                </Grid>

              </Link>
            </CardActionArea>
          </CardContent>
          <Box style={{ padding: '8px 2px 16px 16px' }}>
            <Grid
              container
              alignItems="center"
            >
              <Grid item xs={10.5}>
                <TagsDisplay tags={tags} element={element} handleSearchTags={functions?.handleSearchTags} />
              </Grid>

              <Grid item xs={1.5} container>
                <IconButton style={{ padding: 3, color: '#D9D9D9' }} size="small" aria-label="More options" onClick={handleClick}
                  aria-haspopup="true">
                  <MoreHorizontalIcon size="20" />
                </IconButton>
                <OptionMenu anchorEl={menuAnchorEl} handleClose={handleClose} options={favourite ? optionsForFavourite : optionsForNonFavourite} />
              </Grid>
            </Grid>
          </Box>
        </Card >
      }
      {confirmationDialog && <ConfirmationDialog content={confirmationDialog} handleOpen={handleConfirmationDialog} />}
    </>
  )
};

export default LinkCard;
