import LinkGroupContext from '../../../context/link-group-context';
import { Grid } from '@material-ui/core';
import ImageUploadAndPreview from '../components/ImageUploadAndPreview';
import LinkGroupContent from '../components/LinkGroupContent';


const Content = (props) => {
    const functions = props.functions;

    return (
        <LinkGroupContext.Consumer>
            {context => (
                <Grid container spacing={2} sx={{ py: 3 }}>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12} md={9} >
                            <LinkGroupContent
                                functions={functions}
                                title={props.title}
                                description={props.description}
                                tagsMap={context.tagsMap}
                                roles={props.roles}
                                rolesMap={context.rolesMap}
                                tags={props.tags}
                                isVerified={props.isVerified}
                                links={props.links}
                                isRecurring={props.isRecurring}
                            />
                        </Grid>
                        <Grid item container xs={12} md={3}>
                            <ImageUploadAndPreview
                                compressedImage={props.compressedImage}
                                handleImageUpload={functions.handleImageUpload}
                                handleImageDelete={functions.handleImageDelete}
                                imageName={props.imageName}
                                imageProgress={props.imageProgress}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </LinkGroupContext.Consumer>
    );
}

export default Content;