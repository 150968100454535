import { useEffect, useReducer, Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Container,
  Grid,
  LinearProgress
} from '@material-ui/core';

import LinkGroupListToolbar from '../components/link/LinkGroupListToolbar';
import LinkCard from '../components/link/LinkCard';
import AddNewLinkGroup from '../components/dialog/AddNewLinkGroup';
import UpdateLinkGroup from '../components/dialog/UpdateLinkGroup';
import ViewLinkGroup from '../components/dialog/ViewLinkGroup';
import ConfirmationDialog from '../components/dialog/ConfirmationDialog';
import useHttp from '../utils/http';
import LinkGroupContext from '../context/link-group-context';
import EmptyText from '../components/ui/EmptyText';
import { APP_CONFIG, APP_STORE_INFO } from '../config.js';

import { getUserName } from '../utils/oauth';
import { validateSearch } from '../utils/validate';


const ACTIONS = {
  SET_LOAD_DATA: 'SET_LOAD_DATA',
  SET_GROUP_LINKS: 'SET_GROUP_LINKS',
  SET_GROUP_LINKS_MAP: 'SET_GROUP_LINKS_MAP',
  SET_ROLES_MAP: 'SET_ROLES_MAP',
  SET_TAGS_MAP: 'SET_TAGS_MAP',
  SET_FAVOURITES_MAP: 'SET_FAVOURITES_MAP',
  SET_ADD_NEW_LINK_GROUP_DIALOG: 'SET_ADD_NEW_LINK_GROUP_DIALOG',
  SET_UPDATE_LINK_GROUP_DIALOG: 'SET_UPDATE_LINK_GROUP_DIALOG',
  SET_VIEW_LINK_GROUP_DIALOG: 'SET_VIEW_LINK_GROUP_DIALOG',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_SEARCH_TAGS: 'SET_SEARCH_TAGS',
  HANDLE_RESET: 'HANDLE_RESET',
}

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute !important',
    bottom: theme.spacing(4),
    right: theme.spacing(5),
    textTransform: 'none !important'
  },
}));

const linksReducer = (curLinkState, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOAD_DATA:
      return { ...curLinkState, tagsMap: action.tagsMap, rolesMap: action.rolesMap, favouritesMap: action.favouritesMap }
    case ACTIONS.SET_GROUP_LINKS:
      return { ...curLinkState, linkGroups: action.linkGroups, groupLinksMap: action.groupLinksMap, groupLinksTagsMap: action.groupLinksTagsMap, groupLinksRolesMap: action.groupLinksRolesMap }
    case ACTIONS.SET_TAGS_MAP:
      return { ...curLinkState, tagsMap: action.tagsMap }
    case ACTIONS.SET_ROLES_MAP:
      return { ...curLinkState, rolesMap: action.rolesMap }
    case ACTIONS.SET_FAVOURITES_MAP:
      return { ...curLinkState, favouritesMap: action.favouritesMap }
    case ACTIONS.SET_ADD_NEW_LINK_GROUP_DIALOG:
      return { ...curLinkState, isOpenAddNewLinkGroup: action.isOpenAddNewLinkGroup }
    case ACTIONS.SET_UPDATE_LINK_GROUP_DIALOG:
      return { ...curLinkState, isOpenUpdateGroupLink: action.isOpenUpdateGroupLink, groupLinkId: action.groupLinkId }
    case ACTIONS.SET_VIEW_LINK_GROUP_DIALOG:
      return { ...curLinkState, isOpenViewGroupLink: action.isOpenViewGroupLink, groupLinkId: action.groupLinkId }
    default:
      throw new Error('Should not get here');
  }
}

const searchReducer = (curSearchState, action) => {
  switch (action.type) {
    case ACTIONS.SET_SEARCH_TEXT:
      return { ...curSearchState, searchText: action.searchText }
    case ACTIONS.SET_SEARCH_TAGS:
      return { ...curSearchState, searchTags: action.searchTags }
    default:
      throw new Error('Should not get here');
  }
}

const applyFilters = (linkGroupMap) => {
  var arrayToReturn = Object.values(linkGroupMap).sort(function (a, b) {
    let aCreatedDate = a.createdDate;
    let bCreatedDate = b.createdDate;

    if (bCreatedDate < aCreatedDate) {
      return -1;
    }
    if (bCreatedDate > aCreatedDate) {
      return 1;
    }
    return 0;
  });
  return arrayToReturn;
};

const LinkGroupList = (props) => {
  const [{ linkGroups, groupLinksMap, groupLinksTagsMap, tagsMap, rolesMap, groupLinksRolesMap, favouritesMap, isOpenAddNewLinkGroup, isOpenUpdateGroupLink, isOpenViewGroupLink, groupLinkId }, dispatchLinks] = useReducer(linksReducer,
    { linkGroups: [], groupLinksMap: {}, groupLinksTagsMap: {}, tagsMap: {}, rolesMap: {}, groupLinksRolesMap: {}, favouritesMap: {}, isOpenAddNewLinkGroup: false, isOpenUpdateGroupLink: false, isOpenViewGroupLink: false, groupLinkId: null, });
  const [{ searchText, searchTags, }, dispatchSearch] = useReducer(searchReducer, { searchText: "", searchTags: [] });
  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();
  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const [isLoadingLinks, setIsLoadingLinks] = useState(false);
  const [linkCardTagsMap, setLinkCardTagsMap] = useState({});

  const loadData = () => {
    sendRequest(APP_CONFIG.APIS.LOAD_DATA, 'GET', null, APP_CONFIG.APIS.LOAD_DATA);
  };

  const getLinkGroups = () => {
    if (searchText || searchTags.length) {
      getLinkGroupsWithSearch();
    } else {
      sendRequest(APP_CONFIG.APIS.GET_LINK_GROUPS, 'GET', null, "GET_LINK_GROUPS");
    }
  };

  const getLinkGroupsWithSearch = () => {
    var payload = {
      search: validateSearch(searchText),
      tags: searchTags.map(tag => tag.id)
    };
    sendRequest(APP_CONFIG.APIS.GET_LINK_GROUPS_WITH_SEARCH, 'POST', JSON.stringify(payload), "GET_LINK_GROUPS");
  };

  const changeFavourite = (linkGroupId, isAdd) => {
    var payload = {
      "linkGroupId": linkGroupId,
      "email": getUserName(),
      "isAdd": isAdd
    }
    sendRequest(APP_CONFIG.APIS.CHANGE_FAVOURITE, 'POST', JSON.stringify(payload), APP_CONFIG.APIS.CHANGE_FAVOURITE);
  };

  //Event handlers
  const handleAddNewLinkGroup = (isOpen) => {
    dispatchLinks({ type: ACTIONS.SET_ADD_NEW_LINK_GROUP_DIALOG, isOpenAddNewLinkGroup: isOpen, });
  };

  const handleUpdateGroupLink = (isOpen, id) => {
    dispatchLinks({ type: ACTIONS.SET_UPDATE_LINK_GROUP_DIALOG, isOpenUpdateGroupLink: isOpen, groupLinkId: id, });
  };

  const handleDeleteLinkGroup = (linkGroupId) => {
    linkGroupId && sendRequest(APP_CONFIG.APIS.DELETE_LINK_GROUP + "/" + linkGroupId, 'DELETE', null, APP_CONFIG.APIS.DELETE_LINK_GROUP);
  };

  const handleViewGroupLink = (isOpen, id) => {
    dispatchLinks({ type: ACTIONS.SET_VIEW_LINK_GROUP_DIALOG, isOpenViewGroupLink: isOpen, groupLinkId: id, });
  };

  const handleSearchText = (event) => {
    dispatchSearch({ type: ACTIONS.SET_SEARCH_TEXT, searchText: event.target.value, });
  };

  const handleSearchTags = (tags) => {
    dispatchSearch({ type: ACTIONS.SET_SEARCH_TAGS, searchTags: tags, });
  };

  const handleFavourite = (event, linkGroupId) => {
    //Collection ID is dropped from map
    if (event.target.checked) {
      favouritesMap[linkGroupId] = { linkGroupId: linkGroupId };
      changeFavourite(linkGroupId, true);
    } else {
      favouritesMap[linkGroupId] = null;
      changeFavourite(linkGroupId, false);
    }

    dispatchLinks({ type: ACTIONS.SET_FAVOURITES_MAP, favouritesMap: favouritesMap });
  };

  const handleConfirmationDialog = (content) => {
    setConfirmationDialog(content);
  };

  const load = () => {
    loadData();
    getLinkGroups();
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    getLinkGroups();
  }, [searchTags]);

  useEffect(() => {
    var tempMap = {};
    linkGroups.forEach(linkGroup => {
      if (linkGroup?.linkGroupId) {
        var tagsArray = [];
        if (groupLinksTagsMap[linkGroup?.linkGroupId]) {
          tagsArray = groupLinksTagsMap[linkGroup?.linkGroupId].map((e) => {
            return tagsMap[e];
          });
        }
        tempMap[linkGroup.linkGroupId] = tagsArray;
      }
    });

    setLinkCardTagsMap(tempMap);
  }, [groupLinksTagsMap, tagsMap]);

  useEffect(() => {
    switch (reqExtra) {
      case "GET_LINK_GROUPS":
        setIsLoadingLinks(isLoading);
        if (data) {
          var groupLinksMapNew = {}
          var groupLinksTagsMap = {};
          var groupLinksRolesMap = {};
          if (data.linkGroups) {
            data.linkGroups.forEach(element => {
              var linkElement = {
                id: element.id,
                link: element.link,
                validTo: element.validTo,
                validFrom: element.validFrom,
                versionName: element.versionName,
                period: element.period,
              };
              if (groupLinksMapNew[element.linkGroupId]) {
                var linkGroupElement = groupLinksMapNew[element.linkGroupId];
                var linksArray = linkGroupElement.links.slice();
                linksArray.push(linkElement);
                linkGroupElement.links = linksArray;
              } else {
                var elementToPush = {
                  title: element.title,
                  description: element.description,
                  linkGroupId: element.linkGroupId,
                  links: [linkElement],
                  preview: element.preview,
                  isVerified: element.isVerified,
                  isRecurring: element.isRecurring,
                  createdDate: element.createdDate,
                  addedBy: element.addedBy,
                };
                groupLinksMapNew[element.linkGroupId] = elementToPush;
              }
              if (element.roles) {
                groupLinksRolesMap[element.linkGroupId] = element.roles.split(',');
              }
            });
          }

          if (data.tags) {
            data.tags.forEach((e) => {
              groupLinksTagsMap[e.linkGroupId] = e.tagIds.split(',');
            });
          }
          dispatchLinks({ type: ACTIONS.SET_GROUP_LINKS, linkGroups: applyFilters(groupLinksMapNew), groupLinksMap: groupLinksMapNew, groupLinksTagsMap: groupLinksTagsMap, groupLinksRolesMap: groupLinksRolesMap });
        }
        break;
      case APP_CONFIG.APIS.LOAD_DATA:
        if (data) {
          let tagsMap = {};
          let rolesMap = {};
          let favouritesMap = {};
          if (data.tags) {
            data.tags.forEach(tag => {
              if (tag.id) {
                tagsMap[tag.id] = tag;
              }
            });
          }
          if (data.roles) {
            data.roles.forEach(role => {
              if (role.id) {
                rolesMap[role.id] = role;
              }
            });
          }
          if (data.favourites) {
            data.favourites.forEach(favourite => {
              if (favourite.linkGroupId) {
                favouritesMap[favourite.linkGroupId] = favourite;
              }
            });
          }
          dispatchLinks({ type: ACTIONS.SET_LOAD_DATA, tagsMap: tagsMap, rolesMap: rolesMap, favouritesMap: favouritesMap });
        }
        break;
      case APP_CONFIG.APIS.GET_TAGS:
        if (data) {
          var tagsMap = {};
          data.forEach(tag => {
            if (tag.id) {
              tagsMap[tag.id] = tag;
            }
          });
          dispatchLinks({ type: ACTIONS.SET_TAGS_MAP, tagsMap: tagsMap });
        }
        break;
      case APP_CONFIG.APIS.GET_ROLES:
        if (data) {
          var rolesMap = {};
          data.forEach(role => {
            if (role.id) {
              rolesMap[role.id] = role;
            }
          });
          dispatchLinks({ type: ACTIONS.SET_ROLES_MAP, rolesMap: rolesMap });
        }
        break;
      case "GET_FAVOURITES":
        if (data) {
          var favouritesMap = {};
          data.forEach(favourite => {
            if (favourite.linkGroupId) {
              favouritesMap[favourite.linkGroupId] = favourite;
            }
          });
          dispatchLinks({ type: ACTIONS.SET_FAVOURITES_MAP, favouritesMap: favouritesMap });
        }
        break;
      case APP_CONFIG.APIS.CHANGE_FAVOURITE:
        if (!error && data && data.message) {
          props.handleSnackbar(data.message, "success");
        } else if (error) {
          var errorMessage = "Error updating favourites!";
          if (data && data.message) {
            errorMessage = data.message;
          }
          props.handleSnackbar(errorMessage, "error");
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);


  const getTagsArray = () => {
    let tagsArray = [];
    let linkGroupTags = groupLinksTagsMap[groupLinkId] ? groupLinksTagsMap[groupLinkId] : [];
    linkGroupTags.forEach((tagId) => {
      let tagToPush = tagsMap[tagId];
      if (tagToPush) {
        tagsArray.push(tagToPush);
      }
    })
    return tagsArray;
  };

  const getRolesArray = () => {
    let rolesArray = [];
    let linkGroupRoles = groupLinksRolesMap[groupLinkId] ? groupLinksRolesMap[groupLinkId] : [];
    linkGroupRoles.forEach((roleId) => {
      let rolesToPush = rolesMap[roleId];
      if (rolesToPush) {
        rolesArray.push(rolesToPush);
      }
    })
    return rolesArray;
  };

  const functions = {
    handleUpdateGroupLink,
    handleViewGroupLink,
    handleSearchTags,
    handleFavourite,
    handleConfirmationDialog,
    handleDeleteLinkGroup,
    load,
    handleSnackbar: props.handleSnackbar,
  };


  return (
    <Fragment>
      <LinkGroupContext.Provider value={{
        linkGroups, groupLinksMap, groupLinksTagsMap, tagsMap, rolesMap, isOpenAddNewLinkGroup, isOpenUpdateGroupLink, groupLinkId, handleSnackbar: props.handleSnackbar
      }}>
        <Helmet>
          <title>Home | WSO2 App Store</title>
        </Helmet>
        <Fragment>
          <Box
            sx={{
              backgroundColor: 'background.default',
              minHeight: '100%',
              py: 3
            }}
          >
            <Container maxWidth={false}>
              <LinkGroupListToolbar searchTags={searchTags} handleAddNewLinkGroup={handleAddNewLinkGroup} searchText={searchText} handleSearchText={handleSearchText} getLinkGroups={getLinkGroups} tagsMap={tagsMap} handleSearchTags={handleSearchTags} />
              <Box>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item xs={12}>
                    {isLoadingLinks && <LinearProgress color="secondary" />}
                  </Grid>
                  {linkGroups.length ?
                    <>
                      {
                        linkGroups.map((linkGroup) => (
                          <Grid
                            item
                            key={linkGroup.linkGroupId}
                            xl={2.4}
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                          >
                            {linkGroup.linkGroupId ?
                              <LinkCard linkGroup={linkGroup} isFavourite={favouritesMap[linkGroup.linkGroupId]} tags={linkCardTagsMap[linkGroup.linkGroupId]} onEdit={handleUpdateGroupLink} functions={functions} />
                              : null}
                          </Grid>
                        ))
                      }
                      {/* <ReactWindowGrid/> */}
                    </>
                    :
                    <Grid item xs={12}>
                      <EmptyText text={
                        isLoadingLinks ? APP_STORE_INFO.LOAD_DATA_MESSAGE : APP_STORE_INFO.NO_APPS_TO_SHOW_MESSAGE
                      } />
                    </Grid>
                  }
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: 3
                }}
              >
              </Box>
            </Container>
          </Box>
          {isOpenAddNewLinkGroup && <AddNewLinkGroup isOpen={isOpenAddNewLinkGroup} handleOpen={handleAddNewLinkGroup} getLinkGroups={getLinkGroups} handleSnackbar={props.handleSnackbar} />}
          {isOpenUpdateGroupLink && <UpdateLinkGroup isOpen={isOpenUpdateGroupLink} handleOpen={handleUpdateGroupLink} getLinkGroups={getLinkGroups} data={groupLinkId && groupLinksMap[groupLinkId] ? groupLinksMap[groupLinkId] : []}
            tags={getTagsArray()} roles={getRolesArray()} handleSnackbar={props.handleSnackbar} />}
          {isOpenViewGroupLink && <ViewLinkGroup isOpen={isOpenViewGroupLink} handleOpen={handleViewGroupLink} getLinkGroups={getLinkGroups} data={groupLinkId && groupLinksMap[groupLinkId] ? groupLinksMap[groupLinkId] : []}
            tags={getTagsArray()} roles={getRolesArray()} />}
          {confirmationDialog && <ConfirmationDialog content={confirmationDialog} handleOpen={handleConfirmationDialog} />}
        </Fragment>
      </LinkGroupContext.Provider>
    </Fragment>
  )
};

export default LinkGroupList;
