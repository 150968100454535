import { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItem, ListItemButton as MuiListItemButton, ListItemIcon, Tooltip} from '@material-ui/core';

const NavItem = (props) => {
  const theme = useTheme();
  const { href, title, icon } = props;

  const ListItemButton = withStyles({
    root: {
      "&$selected": {
        backgroundColor: '#E1E1E1 !important',
        "& .MuiListItemIcon-root": {
          color: `#000000`,
        }
      },
      // "&$selected:hover": {
      //   backgroundColor: `${theme.palette.primary.main} !important`,
      //   "& .MuiListItemIcon-root": {
      //     color: `${theme.palette.primary.contrastText}`
      //   }
      // },
      // "&:hover": {
      //   backgroundColor: "blue",
      //   "& .MuiListItemIcon-root": {
      //     color: "white"
      //   }
      // }
    },
    selected: {}
  })(MuiListItemButton);

  useEffect(() => {

  }, [props.selected])

  return (
    <Tooltip title={props.title} placement="right">
      <RouterLink to={href}>
        <ListItem sx={{
          maxWidth: 'max-content',
          px: 0,
          py: 0.5
        }}>
          <ListItemButton selected={props.selected} sx={{
            borderRadius: '8px',
            mb: 0.3, mt: 0.3,
            maxWidth: '48px',
            maxHeight: '48px',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <ListItemIcon sx={{
              height: '16px !',
              width: '16px',
              mb: 0.3,
              mt: 0.3,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {icon()}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </RouterLink>
    </Tooltip>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
