/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, Chip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/core/Autocomplete';

export default function FixedTags(props) {
    const [value, setValue] = useState(props.value);
    const [options, setOptions] = useState(props.options);
    const theme = useTheme();

    useEffect(() => {
        setOptions(props.options?.sort((a, b) => {
            let nameA = a?.name.toLowerCase();
            let nameB = b?.name.toLowerCase();

            return (nameA > nameB) ? 1 : (nameA < nameB) ? -1 : 0;
        }));
        setValue(props.value);
    }, [props.value, props.options]);

    return (
        <Autocomplete
            multiple
            id="fixed-tags-demo"
            value={value}
            onChange={(event, newValue) => {
                props.handleValue(newValue);
            }}
            options={options}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        size="small"
                        color="primary"
                        variant="outlined"
                        style={{
                            borderColor: (option.color ? option.color : theme.palette.primary.main),
                            color: (option.color ? option.color : theme.palette.primary.main),
                            borderRadius: '4px'
                        }}
                        label={option.name}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ fontSize: 15, '& > span': { mr: '10px', fontSize: 18 }, }}
                    {...props}
                >
                    {option.name}
                </Box>
            )}
            size="small"
            sx={{
                maxWidth: 600,
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
                borderRadius: '4px',
            }}
            renderInput={(params) => (
                <TextField {...params} placeholder={props.placeholder} />
            )}
        />
    );
}