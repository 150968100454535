import { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  InputBase,
  Paper,
  ToggleButton,
} from '@material-ui/core';

import {
  Plus as PlusIcon,
} from 'react-feather';
import { styled } from '@material-ui/core/styles';
import ChipSelectorSearch from '../ui/ChipSelectorSearch';
import LoadingButton from '../ui/LoadingSearchButton';
import { DEFINED_ACCESS, checkForUserAccess } from '../../utils/oauth';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 10,
  },
  createNew: {
    textAlign: 'right',
    paddingRight: 12,
  },
  filtersGrid: {
    minWidth: 100,
    textAlign: 'left',
  },
  advancedFilterToggles: {
    padding: '0px 6px !important',
    textTransform: 'none !important',
  },
}));

const advancedFilterOptions = [
  {
    name: "Tags",
  },
  {
    name: "Dates",
  }
];

const ProductListToolbar = (props) => {
  const classes = useStyles();

  const handleCreateNew = () => {
    props.handleAddNewLinkGroup(true);
  }

  const StyledToggleButton = styled(ToggleButton)(
    ({ theme, selected }) => `
    ...(selected && {
      background-color: black,
    }),
  `,
  );

  useEffect(() => {

  }, [props.searchText, props.searchTags])

  useEffect(() => {
  }, [props.tagsMap])

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1} direction="row"
        justifyContent="space-between"
        alignItems="center">

        <Grid item container spacing={1} direction="row"
          justifyContent="flex-start"
          alignItems="center"
          xs={6} sm={9} md={9}
        >
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Paper
              sx={{
                p: '2px 4px', display: 'flex', alignItems: 'center',
                minWidth: 250,
                maxWidth: 400,
                height: 40,
                textAlign: 'left', width: 'auto',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1, }}
                placeholder="Search with keywords"
                inputProps={{ 'aria-label': 'search' }}
                onChange={props.handleSearchText}
                value={props.searchText}
                onKeyPress={(e) => {
                  if (e.code === 'Enter') {
                    props.getLinkGroups();
                  }
                }}
              />
              <LoadingButton search={props.searchText} handleSearch={props.getLinkGroups} />
            </Paper>
          </Grid>
          <Grid item container xs={12} sm={12} md={6} lg={5} direction="row"
            sx={{ minWidth: 250, }}
            justifyContent="flex-start"
            alignItems="center" spacing={1}>
            <Grid item xs={10} style={{ width: '100%', }}>
              <ChipSelectorSearch value={props.searchTags} options={Object.values(props.tagsMap)} handleValue={props.handleSearchTags} placeholder={"Filter by tags"} />
            </Grid>
          </Grid>
        </Grid>
        {checkForUserAccess(DEFINED_ACCESS.CREATE) &&
          <Grid item className={classes.createNew}
            xs={6} sm={3} md={3}
          >
            <Button startIcon={<PlusIcon />} variant="contained" color="secondary" onClick={handleCreateNew}>
              Create New
            </Button>
            {/* TODO */}
            {/* <Button startIcon={<PlusIcon />} variant="contained" color="secondary" onClick={windowOpen('http://localhost:8081/')}>
              Test
            </Button> */}
          </Grid>}
      </Grid>
    </Paper >

  )
};

export default ProductListToolbar;
