import React from 'react';
import Typography from '@material-ui/core/Typography';

const EmptyText = props => {

  return (
    <div style={{
      width: '100%',
      textAlign: 'center',
    }}>
      <Typography variant="overline" display="block">
        {props.text}
      </Typography>
    </div>
  );
}

export default EmptyText;