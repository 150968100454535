// import { CHOREO_HEADERS, OAUTH_CONFIG } from "../config";
export const DEFINED_ACCESS = {
  VIEW: { name: "VIEW", level: 0 },
  EDIT: { name: "EDIT", level: 1 },
  CREATE: { name: "CREATE", level: 2 },
  ADMIN: { name: "ADMIN", level: 3 },
  SUPER: { name: "SUPER", level: 4 },
};

var idToken = null;
var userName = "";
var userRoles = [];

var accessToken = null;
var refreshToken = null;

var refreshTokenFunction = null;
var sessionClearFunction = null;
var getNewTokenTries = 0;
var tokenRefreshRequests = [];

var userAccess = DEFINED_ACCESS.VIEW;
var userRolesToAccessMap = {};
var apiToken = null;

export async function setIdToken(token) {
  idToken = token;
}

export function getIdToken() {
  return idToken;
}

export function setAccessToken(token) {
  accessToken = token;
}

export function getAccessToken() {
  return accessToken;
}

function setRefreshToken(token) {
  refreshToken = token;
}

export function getRefreshToken() {
  return refreshToken;
}

export function setRefreshTokenFunction(refreshFunction) {
  refreshTokenFunction = refreshFunction;
}

export function setSessionClearFunction(sessionClearFn) {
  sessionClearFunction = sessionClearFn;
}

function setToken(token) {
  apiToken = token;
}

export async function handleTokenFailure(callback) {
  tokenRefreshRequests.push(callback);
  if (tokenRefreshRequests.length === 1) {
    try {
      await refreshTokenFunction();

      let callbacksToRun = tokenRefreshRequests.slice();
      callbacksToRun.forEach((e) => {
        let callbackFn = tokenRefreshRequests.shift();
        callbackFn && callbackFn();
      });
    } catch (e) {
      console.error("Could not refresh access token!", e);
      sessionClearFunction && sessionClearFunction();
    } finally {
      tokenRefreshRequests = [];
    }
  }
}

export function setUserName(user) {
  userName = user;
}

export function getUserName() {
  return userName;
}

export function setUserRoles(rolesFromJWT) {
  if (typeof rolesFromJWT === "string") {
    userRoles = rolesFromJWT.split(",");
  } else if (Array.isArray(rolesFromJWT)) {
    userRoles = rolesFromJWT.slice();
  }
}

export function getUserRoles() {
  return userRoles;
}

export function setUserAccessMap(map, callback) {
  userRolesToAccessMap = map;
  setAccess(callback);
}

// Finds the access level for user role
export function getAccessForRole(role) {
  // Default role is set
  let access = DEFINED_ACCESS.VIEW.name;
  if (role) {
    //Gets access level given to role
    let roleAccess = userRolesToAccessMap[role];
    if (roleAccess && DEFINED_ACCESS[roleAccess]) {
      //Returns this access level
      access = roleAccess;
    }
  }
  return access;
}

// Sets the highest access the user has
function setAccess(callback) {
  let highestAccess = DEFINED_ACCESS.VIEW;
  let highestAccessPosition = DEFINED_ACCESS.VIEW.level;
  userRoles.forEach((role) => {
    let roleAccess = userRolesToAccessMap[role];
    if (roleAccess && DEFINED_ACCESS[roleAccess]) {
      let accessPositionForRole = DEFINED_ACCESS[roleAccess].level;
      if (accessPositionForRole > highestAccessPosition) {
        highestAccess = DEFINED_ACCESS[roleAccess];
        highestAccessPosition = DEFINED_ACCESS[roleAccess].level;
      }
    }
  });
  userAccess = highestAccess;
  callback && callback();
}

export function checkForUserAccess(accessRequired, owner) {
  if (owner && owner === getUserName(owner)) {
    return true;
  }
  if (
    accessRequired &&
    accessRequired.name &&
    DEFINED_ACCESS[accessRequired.name]
  ) {
    let requiredAccessLevel = DEFINED_ACCESS[accessRequired.name].level;
    // Gives access if the required level of access is the same or lower than the users access
    if (userAccess.level >= requiredAccessLevel) {
      return true;
    }
  }
  return false;
}

var getNewAPITokenTries = 0;
