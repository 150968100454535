

export function validateURL(url) {
    if (url) {
        const regexUrl = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;
        const regexSpace = /\s/g;
        return Boolean(url.match(regexUrl) && !url.match(regexSpace));
    }
}

export function validateSearch(search) {
    const regex = /'/i;
    return search.replace(regex, '');
}
//TODO CHECK
export function applyLengthValidation(input, length) {
    if(input){
        return input.substring(0,length);
    }
    return input;
}

export function validateInputLength(input, length) {
    if(input){
        return input.substring(0,length);
    }
    return input;
}