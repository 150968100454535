import React, { useState, useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/styles';
import useHttp from '../../utils/http';
import {
    Button,
    Dialog,
    Grid,
    IconButton,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    Typography,
    Stack,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Content from './content/ViewLinkGroupContent';
import { getDateForDB } from '../../utils/formatting';
import { getDatesForPeriod } from '../../utils/utils';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        color: theme.palette.grey[500],
    },
});

const ACTIONS = {
    SET_DATA: 'SET_DATA',
    SET_TITLE: 'SET_TITLE',
    SET_DESCRIPTION: 'SET_DESCRIPTION',
    SET_LINKS: 'SET_LINKS',
    SET_IMAGE: 'SET_IMAGE',
    DELETE_IMAGE: 'DELETE_IMAGE',
    SET_IMAGE_UPLOAD_PROGRESS: 'SET_IMAGE_UPLOAD_PROGRESS',
    SET_TAGS: 'SET_TAGS',
    SET_ROLES: 'SET_ROLES',
    SET_IS_RECURRING: 'SET_IS_RECURRING',
    SET_IS_VERIFIED: 'SET_IS_VERIFIED',
    HANDLE_RESET: 'HANDLE_RESET',
}

const getInitialLinkElement = () => {
    return { link: "", validFrom: new Date(), validTo: new Date() }
};

const linksReducer = (curLinkState, action) => {
    switch (action.type) {
        case ACTIONS.SET_DATA:
            return {
                ...curLinkState, linkGroupId: action.linkGroupId, title: action.title, description: action.description, imageName: action.imageName, links: action.links,
                isRecurring: action.isRecurring, isVerified: action.isVerified, addedBy: action.addedBy
            }
        case ACTIONS.SET_TAGS:
            return { ...curLinkState, tags: action.tags, }
        case ACTIONS.SET_ROLES:
            return { ...curLinkState, roles: action.roles, }
        case ACTIONS.HANDLE_RESET:
            return { title: "", description: "", links: [getInitialLinkElement], imageName: "", compressedImage: null, isRecurring: false, };
        default:
            throw new Error('Should not get here');
    }
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, data, ...other } = props;
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            <Grid container direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Grid item xs>
                    <Typography variant="h4">{children}</Typography>
                </Grid>
                <Grid item>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </Grid>
            </Grid>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);



const CustomizedDialogs = (props) => {
    const [open, setOpen] = useState(false);
    const [{ linkGroupId, title, description, links, imageName, compressedImage, isRecurring, isVerified, tags, roles, addedBy }, dispatchLinks] = useReducer(linksReducer,
        { linkGroupId: null, title: "", description: "", links: [getInitialLinkElement()], imageName: "", compressedImage: null, isRecurring: false, isVerified: false, tags: [], roles: [], addedBy: "" });
    const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();

    //Used for Datepicker as no min date attribute was available
    //TODO ISSUE HERE
    const disablePastDates = (date, index) => {
        if (links[index] && links[index].validFrom instanceof Date) {
            return !(getDateForDB(date) === getDateForDB(links[index].validFrom)) && (date.getTime() < links[index].validFrom.getTime());
        }
        return false;
    }

    const handleClose = () => {
        dispatchLinks({ type: ACTIONS.HANDLE_RESET });
        props.handleOpen(false);
    };

    useEffect(() => {
        setOpen(props.isOpen);
        if (props.data) {
            var linkGroupId = props.data.linkGroupId;
            var title = props.data.title ? props.data.title : "";
            var description = props.data.description ? props.data.description : "";
            var imageName = props.data.preview ? props.data.preview : "";
            var addedBy = props.data?.addedBy ? props.data.addedBy : ""
            var links = props.data.links ?
                props.data.links.map(link => {
                    var dates = getDatesForPeriod(link.period);
                    return {
                        id: link.id, link: link.link,
                        validFrom: (dates.firstDay ? dates.firstDay : link.validFrom),
                        validTo: (dates.lastDay ? dates.lastDay : link.validTo),
                        name: link.versionName, period: link.period, isDynamic: Boolean(link.period)
                    }
                })
                : [getInitialLinkElement()];
            var isRecurring = props.data.isRecurring ? props.data.isRecurring : false;
            var isVerified = props.data.isVerified ? props.data.isVerified : false;

            dispatchLinks({
                type: ACTIONS.SET_DATA, linkGroupId: linkGroupId, title: title, description: description, imageName: imageName, links: links,
                isRecurring: isRecurring, isVerified: isVerified, addedBy: addedBy
            });
        }
    }, [props.isOpen, props.data]);

    useEffect(() => {
        dispatchLinks({ type: ACTIONS.SET_TAGS, tags: props.tags });
    }, [props.tags]);

    useEffect(() => {
        dispatchLinks({ type: ACTIONS.SET_ROLES, roles: props.roles });
    }, [props.roles]);

    const functions = {
        disablePastDates: disablePastDates,
    };

    return (
        <Dialog aria-labelledby="add-new-link-group" open={open}
            fullWidth maxWidth={"xl"}>
            <DialogTitle id="add-new-link-group" onClose={handleClose}>
                View
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    <span>{linkGroupId &&
                        <Content
                            title={title}
                            imageName={imageName}
                            compressedImage={compressedImage}
                            description={description}
                            links={links}
                            isRecurring={isRecurring}
                            isVerified={isVerified}
                            tags={tags}
                            roles={roles}
                            functions={functions}
                        />}
                    </span>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="outlined" onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CustomizedDialogs;