import { useState, useEffect, useContext } from 'react';
import { styled } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import {
  AppBar as MuiAppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Toolbar,
  Typography,
  Link,
} from '@material-ui/core';

import {
  FileText as FileTextIcon,
  HelpCircle as HelpIcon,
  LogOut as LogOutIcon,
  User as PersonIcon,
} from 'react-feather';

import { APP_CONFIG, APP_NAME } from '../config';
import Logo from './Logo';
import UserContext from '../context/user-context';
import { getGmailMailTo } from '../utils/formatting';
import useHttp from '../utils/http';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
}));

const MainNavbar = () => {
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [userProfileImage, setUserProfileImage] = useState(null);
  const openAccount = Boolean(accountAnchorEl);
  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();
  const userContext = useContext(UserContext);

  const handleClickAccount = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAccountAnchorEl(null);
  };

  useEffect(() => {
    if (reqExtra === APP_CONFIG.APIS.GET_PROFILE_IMAGE && data) {
      setUserProfileImage(data.employee.employeeThumbnail);
    }
  }, [data, reqExtra, isOpen, isLoading, error, userProfileImage]);

  useEffect(() => {
    if (userContext.userName) {
      sendRequest(`${APP_CONFIG.APIS.GET_PROFILE_IMAGE}`, 'GET', null, APP_CONFIG.APIS.GET_PROFILE_IMAGE);
    }
  }, [userContext.userName]);

  return (
    <UserContext.Consumer>
      {context => (
        <AppBar
          elevation={0}
          open={true}
        >
          <Toolbar>
            <NavLink to={APP_CONFIG.PAGES.HOME}>
              <Logo />
            </NavLink>
            <Box
              sx={{
                display: 'block',
                height: '16px',
                width: '2px',
                backgroundColor: '#747474',
                ml: 1,
                mr: 2.5,
              }}
            >
            </Box>
            <Typography variant="h4"
              // className={classes.title} 
              noWrap>
              {`${APP_NAME}`}
            </Typography>
            {/* </RouterLink> */}
            <Box sx={{ flexGrow: 1 }} />
            <NavLink to={APP_CONFIG.PAGES.SETTINGS + '?view=' + APP_CONFIG.QUERY_VALUES.ACCOUNT}>
              {context.userName ? <Typography variant="subtitle2" noWrap>
                {context.userName}
              </Typography>
                : <></>
              }
            </NavLink>
            <IconButton onClick={handleClickAccount} size="small" sx={{ ml: 2 }}>
              {userProfileImage && context.userName ? <Avatar alt={context.userName} src={userProfileImage} /> :
                <Avatar alt="user profile image">
                  <PersonIcon />
                </Avatar>}
            </IconButton>
            <Menu
              anchorEl={accountAnchorEl}
              open={openAccount}
              onClose={handleCloseAccount}
              onClick={handleCloseAccount}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: 300,
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Link href={APP_CONFIG.URL.USER_GUIDE} target="_blank" rel="noreferrer" underline="none">
                <MenuItem>
                  <ListItemIcon>
                    <FileTextIcon fontSize="small" />
                  </ListItemIcon>
                  User Guide
                </MenuItem>
              </Link>
              <Link href={getGmailMailTo(APP_CONFIG.EMAILS.GET_HELP_EMAIL_TO, APP_CONFIG.EMAILS.GET_HELP_EMAIL_SUBJECT)} target="_blank" rel="noreferrer" underline="none">
                <MenuItem>
                  <ListItemIcon>
                    <HelpIcon fontSize="small" />
                  </ListItemIcon>
                  Get Help
                </MenuItem>
              </Link>
              <Divider />
              <MenuItem onClick={context.handleLogout}>
                <ListItemIcon>
                  <LogOutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      )
      }
    </UserContext.Consumer >
  );
};

export default MainNavbar;
