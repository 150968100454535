import { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, IconButton } from '@material-ui/core';

import {
    Search as SearchIcon,
} from 'react-feather';

export default function LoadingButton(props) {
    const SEARCH_INTERVAL = 90;
    const INTERVAL_ITERATION_SIZE = 10;
    const [success, setSuccess] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const timer = useRef();

    // const buttonSx = {
    //     ...(success && {
    //         bgcolor: green[500],
    //         '&:hover': {
    //             bgcolor: green[700],
    //         },
    //     }),
    // };

    // useEffect(() => {
    //     return () => {
    //         clearInterval(timer.current);
    //     };
    // }, []);

    useEffect(() => {
        return () => {
            clearInterval(timer.current);
            handleWait();
        };
    }, [props.search]);

    useEffect(() => {
        if (success) {
            props.handleSearch();
        }
    }, [success]);

    const handleButtonClick = () => {
        props.handleSearch();
    };

    const handleWait = () => {
        setSuccess(false);
        var progress = 0;
        timer.current = window.setInterval(() => {
            if (progress >= 100) {
                setSuccess(true);
                setLoadingProgress(0);
                clearInterval(timer.current);
            } else {
                progress += INTERVAL_ITERATION_SIZE;
                setLoadingProgress(progress);
            }
        }, (SEARCH_INTERVAL));

    };

    return (
        // <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ m: 1, position: 'relative' }}>
            <IconButton
                aria-label="save"
                color="primary"
                onClick={handleButtonClick}
            >
                <SearchIcon color="#828282" />
            </IconButton>
            {!success && (
                <CircularProgress
                    variant="determinate"
                    color="secondary"
                    size={38}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                    value={loadingProgress}
                />
            )}
        </Box>
    );
}
