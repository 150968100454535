import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle as MuiDialogTitle,
    Grid, IconButton, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function ConfirmationDialog(props) {
    const [content, setContent] = useState(null);

    const handleClose = () => {
        props?.handleOpen(null);
    };

    const handleAction = () => {
        content && content.confirmAction();
        handleClose();
    };

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            color: theme.palette.grey[500],
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, data, ...other } = props;
        return (
            <MuiDialogTitle className={classes.root} {...other}>
                <Grid container direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs>
                        <Typography variant="h4">{children}</Typography>
                    </Grid>
                    <Grid item>
                        {onClose ? (
                            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </Grid>
                </Grid>
            </MuiDialogTitle>
        );
    });

    useEffect(() => {
        setContent(props.content);
    }, [props.content])

    return (
        <>
            {content && <Dialog
                fullWidth
                open={Boolean(content)}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {content.title ? content.title : ""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content.text ? content.text : ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color="secondary" variant="contained" disabled={!content.confirmAction} onClick={handleAction} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>}
        </>
    );
}
