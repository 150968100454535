import { useReducer, useCallback } from "react";
import { getAccessToken, getIdToken, handleTokenFailure } from "./oauth";
import { imageContentToImage } from "./utils";

const httpReducer = (curHttpState, action) => {
  switch (action.type) {
    case "SEND":
      return {
        loading: true,
        error: null,
        data: null,
        extra: action.extra,
        open: true,
      };
    case "RESPONSE":
      return {
        ...curHttpState,
        loading: false,
        data: action.responseData,
        extra: action.extra,
        open: false,
      };
    case "NO RESPONSE":
      return { loading: false, extra: action.extra, open: false };
    case "ERROR":
      return {
        loading: false,
        extra: action.extra,
        error: action.errorMessage,
      };
    case "CLEAR":
      return { ...curHttpState, error: null };
    default:
      throw new Error("Should not get here");
  }
};

const useHttp = () => {
  const [httpState, dispatchHTTP] = useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
    extra: null,
    open: false,
  });

  const sendRequest = useCallback((url, method, body, reqExtra, isRetry) => {
    var encodedUrl = encodeURI(url);
    dispatchHTTP({ type: "SEND", extra: reqExtra });
    fetch(encodedUrl, {
      method: method,
      body: body ? body : null,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAccessToken(),
      },
    })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.body
        ) {
          return response.json();
        } else if (response.status >= 400) {
          if (isRetry) {
            dispatchHTTP({
              type: "ERROR",
              extra: reqExtra,
              errorMessage:
                "Try reloading the page. If the issue continues, please contact internal-apps-group@wso2.com.",
            });
            return;
          }
          if (response.status === 401) {
            handleTokenFailure(() =>
              sendRequest(encodedUrl, method, body, reqExtra, true)
            );
          } else {
            response
              .json()
              .then((errorResponse) => {
                dispatchHTTP({
                  type: "ERROR",
                  extra: reqExtra,
                  errorMessage: errorResponse,
                });
              })
              .catch((err) => {
                dispatchHTTP({
                  type: "ERROR",
                  extra: reqExtra,
                  errorMessage: err,
                });
              });

            return;
          }
        } else {
          dispatchHTTP({ type: "NO RESPONSE", extra: reqExtra });
          return;
        }
      })
      .then((response) => {
        if (response) {
          dispatchHTTP({
            type: "RESPONSE",
            responseData: response,
            extra: reqExtra,
          });
          return;
        }
      })
      .catch((er) => {
        dispatchHTTP({ type: "ERROR", extra: reqExtra, errorMessage: er });
        console.error("Error", er);
      });
  }, []);

  const handleFileRequest = async (url, method, body, reqExtra) => {
    try {
      var encodedUrl = encodeURI(url);
      const response = await fetch(encodedUrl, {
        method: method,
        body: body,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let responseBody = await response.json();
      if (response.ok) {
        let blobString = imageContentToImage(responseBody.blobContent);
        dispatchHTTP({
          type: "RESPONSE",
          responseData: blobString,
          extra: reqExtra,
        });
      } else {
        console.error(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const clearError = () => {
    dispatchHTTP({ type: "CLEAR" });
  };
  return {
    isLoading: httpState.loading,
    data: httpState.data,
    error: httpState.error,
    sendRequest,
    handleFileRequest,
    // sendRequestWithJWT: sendRequestWithJWT,
    // sendEmail: sendEmail,
    clearError: clearError,
    reqExtra: httpState.extra,
    isOpen: httpState.open,
  };
};

export default useHttp;
