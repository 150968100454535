export const APP_NAME = "App Store";
export const APP_DESCRIPTION = "WSO2 App Store - A place to access your apps";
export const APP_CONTEXT = "";
const API_BASE_URL = window.config?.BACKEND_API_BASE_URL;
export const AUTH_CONFIG = {
  clientID: window.config?.AUTH_CLIENT_ID,
  baseUrl: window.config?.AUTH_SERVER_ORIGIN,
  signInRedirectURL: window.config?.AUTH_SIGN_IN_REDIRECT_URL,
  signOutRedirectURL: window.config?.AUTH_SIGN_OUT_REDIRECT_URL,
  scope: ["openid", "email", "groups"],
};

export const APP_CONFIG = {
  URL: {
    USER_GUIDE: window.config?.USER_GUIDE_URL,
  },
  PAGES: {
    APP: APP_CONTEXT,
    HOME: APP_CONTEXT + "/home",
    RECURRING: APP_CONTEXT + "/recurring",
    COLLECTIONS: APP_CONTEXT + "/collections",
    FAVOURITES: APP_CONTEXT + "/favourites",
    SETTINGS: APP_CONTEXT + "/settings",
  },
  APIS: {
    INITIALIZE_APP: API_BASE_URL + "/role-privileges",
    LOAD_DATA: API_BASE_URL + "/app-data",
    GET_LINK_GROUPS: API_BASE_URL + "/link-groups",
    GET_LINK_GROUPS_WITH_SEARCH:
      API_BASE_URL + "/link-groups-tags-names-search",
    GET_LINK_GROUP_BY_IDS: API_BASE_URL + "/link-groups-ids-search",
    GET_TAGS: API_BASE_URL + "/tags",
    GET_ROLES: API_BASE_URL + "/roles",
    GET_FAVOURITES: API_BASE_URL + "/favourites",
    GET_FAVOURITE_LINK_GROUPS: API_BASE_URL + "/favourite-link-groups",
    GET_COLLECTIONS: API_BASE_URL + "/collections",
    CHANGE_FAVOURITE: API_BASE_URL + "/favourites",
    INSERT_LINK_GROUP: API_BASE_URL + "/link-groups",
    UPDATE_LINK_GROUP: API_BASE_URL + "/link-groups",
    DELETE_LINK_GROUP: API_BASE_URL + "/link-groups",
    INSERT_LINK: API_BASE_URL + "/links",
    UPDATE_LINK: API_BASE_URL + "/links",
    DELETE_LINK: API_BASE_URL + "/links",
    ADD_TO_COLLECTION: API_BASE_URL + "/collection-add-link-group",
    REMOVE_FROM_COLLECTION: API_BASE_URL + "/collection-remove-link-group",
    INSERT_COLLECTION: API_BASE_URL + "/collections",
    UPDATE_COLLECTION: API_BASE_URL + "/collections",
    DELETE_COLLECTION: API_BASE_URL + "/collections",
    GET_MY_COLLECTIONS: API_BASE_URL + "/my-collections",
    GET_PUBLIC_COLLECTIONS: API_BASE_URL + "/get-public-collections",
    INSERT_PUBLIC_COLLECTION: API_BASE_URL + "/public-collections",
    DELETE_PUBLIC_COLLECTION: API_BASE_URL + "/public-collections",
    CREATE_TAG: API_BASE_URL + "/tags",
    UPDATE_TAG: API_BASE_URL + "/tags",
    DELETE_TAG: API_BASE_URL + "/tags",
    CREATE_ROLE: API_BASE_URL + "/roles",
    UPDATE_ROLE: API_BASE_URL + "/roles",
    DELETE_ROLE: API_BASE_URL + "/roles",
    IMAGE_URL: API_BASE_URL + "/thumbnail-image",
    FILE_UPLOAD: API_BASE_URL + "/thumbnail-image",
    FILE_DELETE: API_BASE_URL + "/thumbnail-image",
    GET_PROFILE_IMAGE: API_BASE_URL + "/profile-image",
  },
  EMAILS: {
    GET_HELP_EMAIL_TO: window.config?.GET_HELP_EMAIL_TO_ADDRESS,
    GET_HELP_EMAIL_SUBJECT: "[HELP] WSO2 App Store",
  },
  PERIODS: [
    {
      title: "Yesterday",
      value: "YESTERDAY",
      type: "Day",
    },
    {
      title: "Today",
      value: "TODAY",
      type: "Day",
    },
    {
      title: "Tomorrow",
      value: "TOMORROW",
      type: "Day",
    },
    {
      title: "Last 7 Days",
      value: "LAST_7_DAYS",
      type: "Day",
    },
    {
      title: "Last 30 Days",
      value: "LAST_30_DAYS",
      type: "Day",
    },
    {
      title: "Last 60 Days",
      value: "LAST_60_DAYS",
      type: "Day",
    },
    {
      title: "Last 90 Days",
      value: "LAST_90_DAYS",
      type: "Day",
    },
    {
      title: "Last 120 Days",
      value: "LAST_120_DAYS",
      type: "Day",
    },
    {
      title: "Next 7 Days",
      value: "NEXT_7_DAYS",
      type: "Day",
    },
    {
      title: "Next 30 Days",
      value: "NEXT_30_DAYS",
      type: "Day",
    },
    {
      title: "Next 60 Days",
      value: "NEXT_60_DAYS",
      type: "Day",
    },
    {
      title: "Next 90 Days",
      value: "NEXT_90_DAYS",
      type: "Day",
    },
    {
      title: "Next 120 Days",
      value: "NEXT_120_DAYS",
      type: "Day",
    },
    {
      title: "Current FY",
      value: "CURRENT_FY",
      type: "Fiscal Year",
    },
    {
      title: "Previous FY",
      value: "PREVIOUS_FY",
      type: "Fiscal Year",
    },
    {
      title: "Previous 2FY",
      value: "PREVIOUS_2FY",
      type: "Fiscal Year",
    },
    {
      title: "Next FY",
      value: "NEXT_FY",
      type: "Fiscal Year",
    },
    {
      title: "Current and Previous FY",
      value: "CURRENT_AND_PREVIOUS_FY",
      type: "Fiscal Year",
    },
    {
      title: "Current and Previous 2FY",
      value: "CURRENT_AND_PREVIOUS_2FY",
      type: "Fiscal Year",
    },
    {
      title: "Current and Next FY",
      value: "CURRENT_AND_NEXT_FY",
      type: "Fiscal Year",
    },
    {
      title: "Current CY",
      value: "CURRENT_CY",
      type: "Calendar Year",
    },
    {
      title: "Previous CY",
      value: "PREVIOUS_CY",
      type: "Calendar Year",
    },
    {
      title: "Previous 2 CY",
      value: "PREVIOUS_2_CY",
      type: "Calendar Year",
    },
    {
      title: "Next CY",
      value: "NEXT_CY",
      type: "Calendar Year",
    },
    {
      title: "Current and Previous CY",
      value: "CURRENT_AND_PREVIOUS_CY",
      type: "Calendar Year",
    },
    {
      title: "Current and Previous 2CY",
      value: "CURRENT_AND_PREVIOUS_2CY",
      type: "Calendar Year",
    },
    {
      title: "Current and Next CY",
      value: "CURRENT_AND_NEXT_CY",
      type: "Calendar Year",
    },
    {
      title: "Current CQ",
      value: "CURRENT_CQ",
      type: "Calendar Quarter",
    },
    {
      title: "Current and Next CQ",
      value: "CURRENT_AND_NEXT_CQ",
      type: "Calendar Quarter",
    },
    {
      title: "Current and Previous CQ",
      value: "CURRENT_AND_PREVIOUS_CQ",
      type: "Calendar Quarter",
    },
    {
      title: "Next CQ",
      value: "NEXT_CQ",
      type: "Calendar Quarter",
    },
    {
      title: "Previous CQ",
      value: "PREVIOUS_CQ",
      type: "Calendar Quarter",
    },
    {
      title: "Current and Next 3 CQ",
      value: "CURRENT_AND_NEXT_3_CQ",
      type: "Calendar Quarter",
    },
    {
      title: "This month",
      value: "THIS_MONTH",
      type: "Calendar Month",
    },
    {
      title: "Last month",
      value: "LAST_MONTH",
      type: "Calendar Month",
    },
    {
      title: "Next month",
      value: "NEXT_MONTH",
      type: "Calendar Month",
    },
  ],
  FINANCIAL_START_MONTH: 0, //0 is January, 11 is December
  QUERY_VALUES: {
    VIEW: "view",
    EDIT: "edit",
    ACCOUNT: "account",
    TAGS: "tags",
    ROLES: "roles",
  },
};

export const APP_STORE_INFO = {
  COLLECTIONS_PAGE_DESCRIPTION:
    "Create a collection to add the apps you want to organize and access conveniently.",
  FAVOURITES_PAGE_DESCRIPTION:
    "Apps you have marked as favourites will be shown here.",
  TAGS_PAGE_COLOR_PICKER:
    "Make sure to pick a dark color as the background color will always be white.",
  CREATE_COLLECTIONS_TOOLTIP:
    "Create your own collection to list the apps you require",
  GET_PUBLIC_COLLECTIONS_TOOLTIP:
    "Add public collections created by others, to your own collections list for easy access",
  SINGLE_LINK_DESCRIPTION: "These contain a single link",
  RECURRING_LINK_DESCRIPTION:
    "These contain related and/or periodically generated links",
  NO_APPS_TO_SHOW_MESSAGE: "No apps to show",
  LOAD_DATA_MESSAGE: "Loading . . .",
};

export const VALIDATION = {
  LINK_GROUP_TITLE_LENGTH: 200,
  LINK_GROUP_DESCRIPTION_LENGTH: 80,
  LINK_LENGTH: 300,
  VERSION_LENGTH: 30,
  COLLECTION_NAME_LENGTH: 75,
};
