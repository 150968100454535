import { APP_CONFIG } from "../config";

const periodMap = intializePeriods();

function intializePeriods() {
    var tempMap = {};
    APP_CONFIG.PERIODS.forEach(e => {
        tempMap[e.value] = e;
    });
    return tempMap;
}
/**
 * To safely open external links in a new tab
 * @param {URL to open} url 
 * @returns 
 */
export function windowOpen(url) {
    //Adds http:// prefix if missing
    //Try was used to stop "Cannot read property 'blur' of null" error
    try {
        if (!url) {
            return;
        }
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        window.open(url, "_blank", "noopener", "noreferrer");
    } catch (e) {
        console.error(e);
    }

}

/**
 * To get valid URL
 * @param {URL} url 
 * @returns 
 */
export function getOpenUrl(url) {
    if (!url) {
        return;
    }
    if (!url.match(/^https?:\/\//i)) {
        url = 'http://' + url;
    }
    return url;
}

export function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
    });
}

// export getTimePeriod

function getQuarter(date) {
    date = date || new Date();
    var quarter = Math.floor(date.getMonth() / 3) + 1;
    quarter -= quarter > 4 ? 4 : 0;
    return quarter;
}

export function getDatesForPeriod(period) {
    var firstDay = null;
    var lastDay = null;
    if (period) {
        var date = new Date();
        var financialStartMonth = APP_CONFIG.FINANCIAL_START_MONTH;
        var financialStartYear = (financialStartMonth <= date.getMonth() ? date.getFullYear() : date.getFullYear() - 1)
        switch (period) {
            case periodMap.YESTERDAY.value:
                let yesterday = date;
                yesterday.setDate(date.getDate() - 1);

                firstDay = yesterday;
                lastDay = yesterday;
                break;
            case periodMap.TODAY.value:
                firstDay = date;
                lastDay = date;
                break;
            case periodMap.TOMORROW.value:
                let tomorrow = date;
                tomorrow.setDate(date.getDate() + 1);

                firstDay = tomorrow;
                lastDay = tomorrow;
                break;
            case periodMap.LAST_7_DAYS.value:
                let last7Days = new Date();
                last7Days.setDate(date.getDate() - 7);

                firstDay = last7Days;
                lastDay = date;
                break;
            case periodMap.LAST_30_DAYS.value:
                let last30Days = new Date();
                last30Days.setDate(date.getDate() - 30);

                firstDay = last30Days;
                lastDay = date;
                break;
            case periodMap.LAST_60_DAYS.value:
                let last60Days = new Date();
                last60Days.setDate(date.getDate() - 60);

                firstDay = last60Days;
                lastDay = date;
                break;
            case periodMap.LAST_90_DAYS.value:
                let last90Days = new Date();
                last90Days.setDate(date.getDate() - 90);

                firstDay = last90Days;
                lastDay = date;
                break;
            case periodMap.LAST_120_DAYS.value:
                let last120Days = new Date();
                last120Days.setDate(date.getDate() - 120);

                firstDay = last120Days;
                lastDay = date;
                break;
            case periodMap.NEXT_7_DAYS.value:
                let next7Days = new Date();
                next7Days.setDate(date.getDate() + 7);

                firstDay = date;
                lastDay = next7Days;
                break;
            case periodMap.NEXT_30_DAYS.value:
                let next30Days = new Date();
                next30Days.setDate(date.getDate() + 30);

                firstDay = date;
                lastDay = next30Days;
                break;
            case periodMap.NEXT_60_DAYS.value:
                let next60Days = new Date();
                next60Days.setDate(date.getDate() + 60);

                firstDay = date;
                lastDay = next60Days;
                break;
            case periodMap.NEXT_90_DAYS.value:
                let next90Days = new Date();
                next90Days.setDate(date.getDate() + 90);

                firstDay = date;
                lastDay = next90Days;
                break;
            case periodMap.NEXT_120_DAYS.value:
                let next120Days = new Date();
                next120Days.setDate(date.getDate() + 120);

                firstDay = date;
                lastDay = next120Days;
                break;
            case periodMap.THIS_MONTH.value:
                firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                break;
            case periodMap.LAST_MONTH.value:
                firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
                break;
            case periodMap.NEXT_MONTH.value:
                firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
                lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
                break;
            case periodMap.CURRENT_CY.value:
                firstDay = new Date(date.getFullYear(), 0, 1);
                lastDay = new Date(date.getFullYear(), 11, 31);
                break;
            case periodMap.PREVIOUS_CY.value:
                firstDay = new Date(date.getFullYear() - 1, 0, 1);
                lastDay = new Date(date.getFullYear() - 1, 11, 31);
                break;
            case periodMap.PREVIOUS_2_CY.value:
                firstDay = new Date(date.getFullYear() - 2, 0, 1);
                lastDay = new Date(date.getFullYear() - 1, 11, 31);
                break;
            case periodMap.NEXT_CY.value:
                firstDay = new Date(date.getFullYear() + 1, 0, 1);
                lastDay = new Date(date.getFullYear() + 1, 11, 31);
                break;
            case periodMap.CURRENT_AND_PREVIOUS_CY.value:
                firstDay = new Date(date.getFullYear() - 1, 0, 1);
                lastDay = new Date(date.getFullYear(), 11, 31);
                break;
            case periodMap.CURRENT_AND_PREVIOUS_2CY.value:
                firstDay = new Date(date.getFullYear() - 2, 0, 1);
                lastDay = new Date(date.getFullYear(), 11, 31);
                break;
            case periodMap.CURRENT_AND_NEXT_CY.value:
                firstDay = new Date(date.getFullYear(), 0, 1);
                lastDay = new Date(date.getFullYear() + 1, 11, 31);
                break;
            case periodMap.CURRENT_CQ.value:
                firstDay = new Date(date.getFullYear(), getQuarter() * 3 - 3, 1);
                lastDay = new Date(date.getFullYear(), getQuarter() * 3, 0);
                break;
            case periodMap.CURRENT_AND_NEXT_CQ.value:
                firstDay = new Date(date.getFullYear(), getQuarter() * 3 - 3, 1);
                lastDay = new Date(date.getFullYear(), getQuarter() * 3 + 3, 0);
                break;
            case periodMap.CURRENT_AND_PREVIOUS_CQ.value:
                firstDay = new Date(date.getFullYear(), getQuarter() * 3 - 6, 1);
                lastDay = new Date(date.getFullYear(), getQuarter() * 3, 0);
                break;
            case periodMap.NEXT_CQ.value:
                firstDay = new Date(date.getFullYear(), getQuarter() * 3, 1);
                lastDay = new Date(date.getFullYear(), getQuarter() * 3 + 3, 0);
                break;
            case periodMap.PREVIOUS_CQ.value:
                firstDay = new Date(date.getFullYear(), getQuarter() * 3 - 6, 1);
                lastDay = new Date(date.getFullYear(), getQuarter() * 3 - 3, 0);
                break;
            case periodMap.CURRENT_AND_NEXT_3_CQ.value:
                firstDay = new Date(date.getFullYear(), getQuarter() * 3 - 3, 1);
                lastDay = new Date(date.getFullYear(), getQuarter() * 3 + 9, 0);
                break;
            case periodMap.CURRENT_FY.value:
                firstDay = new Date(financialStartYear, financialStartMonth, 1);
                lastDay = new Date(financialStartYear, financialStartMonth + 12, 0);
                break;
            case periodMap.PREVIOUS_FY.value:
                firstDay = new Date(financialStartYear - 1, financialStartMonth, 1);
                lastDay = new Date(financialStartYear - 1, financialStartMonth + 12, 0);
                break;
            case periodMap.PREVIOUS_2FY.value:
                firstDay = new Date(financialStartYear - 2, financialStartMonth, 1);
                lastDay = new Date(financialStartYear - 1, financialStartMonth + 12, 0);
                break;
            case periodMap.NEXT_FY.value:
                firstDay = new Date(financialStartYear + 1, financialStartMonth, 1);
                lastDay = new Date(financialStartYear + 1, financialStartMonth + 12, 0);
                break;
            case periodMap.CURRENT_AND_PREVIOUS_FY.value:
                firstDay = new Date(financialStartYear - 1, financialStartMonth, 1);
                lastDay = new Date(financialStartYear, financialStartMonth + 12, 0);
                break;
            case periodMap.CURRENT_AND_PREVIOUS_2FY.value:
                firstDay = new Date(financialStartYear - 2, financialStartMonth, 1);
                lastDay = new Date(financialStartYear, financialStartMonth + 12, 0);
                break;
            case periodMap.CURRENT_AND_NEXT_FY.value:
                firstDay = new Date(financialStartYear, financialStartMonth, 1);
                lastDay = new Date(financialStartYear + 1, financialStartMonth + 12, 0);
                break;
            default:
                break;
        }
    }

    return { firstDay, lastDay }
}

export function imageContentToImage(imageData) {
    var binary = '';
    var bytes = new Uint8Array(imageData);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return binary
  }