/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect, Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import { useTheme } from '@material-ui/core/styles';
import { Autocomplete, Box, Chip, Stack, TextField } from '@material-ui/core';

export default function ChipSelector(props) {
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState(props.options);
  const theme = useTheme();

  const handleValue = (event, newValue) => {
    if (newValue) {
      props.handleValue(newValue);
    }
  }

  useEffect(() => {
    setValue(props.value);
    // setOptions(props.options)
    setOptions(props.options?.sort((a, b) => {
      let nameA = a?.name.toLowerCase();
      let nameB = b?.name.toLowerCase();

      return (nameA > nameB) ? 1 : (nameA < nameB) ? -1 : 0;
    }));
  }, [props.value, props.options]);

  useEffect(() => {
  }, [props.readOnly]);

  return (
    <>
      {!props.readOnly ? <Autocomplete
        multiple
        id={"chip-selector" + uuid()}
        value={value}
        onChange={handleValue}
        options={options}
        getOptionLabel={(option) => option.name}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <>{option ? <Chip
              key={uuid()}
              size="small"
              color="primary"
              variant="outlined"
              style={{
                borderColor: (option.color ? option.color : theme.palette.primary.main),
                color: (option.color ? option.color : theme.palette.primary.main),
                height: 24,
                fontSize: '0.8rem',
                borderRadius: '4px'
              }}
              label={option.name}
              {...getTagProps({ index })}
            /> : null}
            </>
          ))
        }
        renderOption={(props, option) => (
          <Box
            key={uuid()}
            component="li"
            sx={{ fontSize: 15, '& > span': { mr: '10px', fontSize: 18 } }}
            {...props}
          >
            {option.name}
          </Box>
        )}
        size="small"
        sx={{ maxWidth: 600, }}
        renderInput={(params) => (
          <TextField {...params} placeholder={props.placeholder}
          />
        )}
      /> :
        <Stack
          sx={{
            p: 1, minHeight: 44, display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
          }}
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          {value && value.map(e => (
            <Fragment key={uuid()}>{
              e ?
                <Chip
                  sx={{ mb: 0.5, ml: 0.5 }}
                  label={e.name}
                  size="small"
                  color="primary"
                  variant="outlined"
                  style={{
                    borderColor: (e.color ? e.color : theme.palette.primary.main),
                    color: (e.color ? e.color : theme.palette.primary.main),
                    height: 24,
                    fontSize: '0.8rem',
                    borderRadius: '4px'
                  }}
                />
                : null}
            </Fragment>
          ))}
        </Stack>}
    </>
  );
}