import { Helmet } from 'react-helmet';
import {
  Container,
  Grid
} from '@material-ui/core';
import AccountProfile from '../components/account/AccountProfile';
import { APP_NAME } from '../config';

const Account = () => (
  <>
    <Helmet>
      <title>Account | {APP_NAME}</title>
    </Helmet>

    <Container maxWidth="xl" >
      <Grid
        container
        alignContent="center"
        justifyContent="center"
      >
        <Grid
          item
          md={6}
          xs={12}
          sx={{ mb: 6 }}
        >
          <AccountProfile />
        </Grid>
      </Grid>
    </Container>
  </>
);

export default Account;
