import { useState, useEffect, Fragment } from 'react';
import "react-color-palette/lib/css/styles.css";
import { Helmet } from 'react-helmet';
import { useTheme } from '@material-ui/core/styles';
import { ColorPicker, useColor, toColor } from "react-color-palette";
import useHttp from '../utils/http';
import {
  Button,
  Chip,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
  Box
} from '@material-ui/core';
import {
  Container,
  Grid
} from '@material-ui/core';
import {
  Save as SaveIcon,
  Plus as PlusIcon,
  RefreshCw as ResetIcon,
  Trash as DeleteIcon,
  X as CancelIcon,
} from 'react-feather';
import { FixedSizeList } from 'react-window';
import SearchBar from '../components/ui/SearchBar';
import { APP_NAME, APP_CONFIG, APP_STORE_INFO } from '../config';
import { validateInputLength } from '../utils/validate';
import EmptyText from '../components/ui/EmptyText';
import ConfirmationDialog from '../components/dialog/ConfirmationDialog';



const TagsPage = (props) => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentTitle, setCurrentTitle] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [currentColor, setCurrentColor] = useState(null);
  const [color, setColor] = useColor("hex", theme.palette.primary.main);
  const [tags, setTags] = useState([]);
  const [searchTags, setSearchTags] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchOrder, setSearchOrder] = useState('asc');
  const [addingNewTag, setAddingNewTag] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(null);

  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setAddingNewTag(false);
    let tagName = searchTags[index]?.name;
    let tagColor = toColor("hex", searchTags[index]?.color || theme.palette.primary.main);
    setCurrentTitle(tagName);
    setEditTitle(tagName);
    setCurrentColor(tagColor);
    setColor(tagColor);
  };

  const handleItemUpdateLoad = (index, tagsArray) => {
    setSelectedIndex(index);
    setAddingNewTag(false);
    let tagName = tagsArray[index]?.name;
    let tagColor = toColor("hex", tagsArray[index]?.color || theme.palette.primary.main);
    setCurrentTitle(tagName);
    setCurrentColor(tagColor);
  };

  const getTags = () => {
    sendRequest(APP_CONFIG.APIS.GET_TAGS, 'GET', null, "GET_TAGS");
  };

  const createTag = () => {
    var payload = {
      "name": editTitle,
      "color": color.hex
    };
    sendRequest(APP_CONFIG.APIS.CREATE_TAG, 'POST', JSON.stringify(payload), "CREATE_TAG");
  };

  const updateTag = () => {
    var tagId = searchTags[selectedIndex]?.id;
    if (tagId) {
      var payload = {
        "id": tagId,
        "name": editTitle,
        "color": color.hex
      };
      sendRequest(APP_CONFIG.APIS.UPDATE_TAG, 'PATCH', JSON.stringify(payload), "UPDATE_TAG");
    }
  };

  const handleDeleteTag = () => {
    var tagId = searchTags[selectedIndex]?.id;
    if (tagId) {
      handleConfirmationDialog({
        title: "WARNING! You are about to delete the folowing tag.",
        text: currentTitle,
        confirmAction: () => { deleteTag(tagId); }
      });
    }
  };

  const deleteTag = (tagId) => {
    sendRequest(APP_CONFIG.APIS.DELETE_TAG + "/" + tagId, 'DELETE', null, "DELETE_TAG");
  };

  const handleNewTag = (isStart) => () => {
    setSelectedIndex(null);
    setCurrentTitle("");
    setEditTitle("");
    setColor(toColor("hex", theme.palette.primary.main));
    setAddingNewTag(isStart);
  };

  const handleChangeCancel = () => {
    handleReset();
  };

  const handleTagName = (event) => {
    let tagName = validateInputLength(event.target.value, 50);
    setEditTitle(tagName);
  };

  const handleSearchText = (value) => {
    setSearchText(value);
  };

  const handleSearchOrder = (isAscending) => {
    setSearchOrder(isAscending ? 'asc' : 'desc');
  };

  const handleReset = (isFull) => {
    if (isFull) {
      let defaultColor = toColor("hex", theme.palette.primary.main);
      setCurrentTitle("");
      setEditTitle("");
      setCurrentColor(defaultColor);
      setColor(defaultColor);
      setSelectedIndex(null);
    } else {
      if (currentTitle || currentColor) {
        setEditTitle(currentTitle);
        setColor(currentColor);
      }
    }
    setAddingNewTag(false)
  };

  const handleSave = () => {
    createTag();
  };

  const handleSaveChanges = () => {
    updateTag();
  };

  const handleConfirmationDialog = (content) => {
    setConfirmationDialog(content);
  };

  const setFilteredCollections = () => {
    let tagstemp = tags;
    tagstemp.sort((a, b) => {
      let nameA = a.name.toLowerCase();
      let nameB = b.name.toLowerCase();
      let searchMultiplier = searchOrder === 'desc' ? -1 : 1;

      return (nameA > nameB) ? (1 * searchMultiplier) : (nameA < nameB) ? (-1 * searchMultiplier) : 0;
    });
    // setTags(tagstemp);
    tagstemp = tagstemp.filter(e => { return e.name.toLowerCase().includes(searchText.toLowerCase()) });
    // setSearchTags(tagstemp);

    setSearchTags(prevState => {
      //To keep updated value when it loads
      if (selectedIndex !== null) {
        let tagId = prevState[selectedIndex]?.id;
        if (tagId) {
          let index = tagstemp.findIndex(e => e?.id === tagId);
          (index >= 0) && handleItemUpdateLoad(index, tagstemp);
        }
      }
      return tagstemp;
    });
  };

  const getIsChanged = () => {
    if (currentTitle === editTitle && currentColor?.hex === color?.hex) {
      return false;
    }
    return true;
  };

  const renderRow = (props) => {
    const { index, style } = props;

    return (
      // <Fragment key={index}>
      <ListItem key={index} style={style} component="div" disablePadding >
        <ListItemButton selected={selectedIndex === index}
          //  autoFocus={Boolean(selectedIndex === index)}
          onClick={(event) => { handleListItemClick(event, index); }}>
          <ListItemText primary={searchTags[index].name} />
        </ListItemButton>
      </ListItem>
      // </Fragment>
    );
  };

  const VirtualizedTagsList = () => {
    return (
      <Paper
        sx={{ width: '60%', height: '100%', bgcolor: 'background.paper' }}
      >
        <FixedSizeList
          width="100%"
          height={500}
          itemCount={searchTags.length}
          itemSize={46}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </Paper>
    );
  };

  useEffect(() => {
    switch (reqExtra) {
      case "GET_TAGS":
        if (data) {
          var tagsMap = {};
          var tagsArray = [];
          data.forEach(tag => {
            if (tag.id) {
              tagsMap[tag.id] = tag;
              tagsArray.push(tag);
            }
          });

          setTags(tagsArray);
        }
        break;
      case "CREATE_TAG":
        if (data && !error) {
          handleReset();
          if (data?.message) {
            props.handleSnackbar(data.message, "success");
          }
          getTags();
        } else if (error) {
          if (error?.message) {
            props.handleSnackbar(error.message, "error");
            return;
          }
          props.handleSnackbar("Failed to create tag!", "error");
        }
        break;
      case "UPDATE_TAG":
        if (data && !error) {
          if (data?.message) {
            props.handleSnackbar(data.message, "success");
          }
          getTags();
        } else if (error) {
          if (error?.message) {
            props.handleSnackbar(error.message, "error");
            return;
          }
          props.handleSnackbar("Failed to update tag!", "error");
        }
        break;
      case "DELETE_TAG":
        if (data && !error) {
          handleReset(true);
          if (data?.message) {
            props.handleSnackbar(data.message, "success");
          }
          getTags();
        } else if (error) {
          if (error?.message) {
            props.handleSnackbar(error.message, "error");
            return;
          }
          props.handleSnackbar("Failed to delete tag!", "error");
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  useEffect(() => {
    setFilteredCollections();
  }, [tags, searchText, searchOrder]);

  useEffect(() => {

  }, [searchTags]);

  useEffect(() => {
    getTags();
  }, []);


  return (
    <Fragment>
      <Helmet>
        <title>Tags | {APP_NAME}</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12}>
            {isLoading && <LinearProgress color="secondary" />}
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ mb: 6 }}
          >
            <Stack direction="column" spacing={2}>
              <span>
                <SearchBar
                  value={searchText}
                  handleSearch={handleSearchText}
                  isAlphabetical={searchOrder === 'asc' ? true : false}
                  handleAlphabetical={handleSearchOrder}
                />
              </span>
              <span>
                {searchTags.length ? <VirtualizedTagsList /> : <EmptyText text="No tags to show" />}
              </span>
            </Stack>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ mb: 6 }}
          >
            <Grid
              container
              direction="column"
              spacing={4}
            >
              <Grid item xs={12}>
                <Stack direction="row"
                  justifyContent="flex-end"
                  alignItems="center" spacing={2}>
                  {addingNewTag ?
                    <Fragment>
                      <span>
                        <Button
                          size="medium"
                          startIcon={<CancelIcon />}
                          variant="contained"
                          color="primary"
                          sx={{ minWidth: 128 }}
                          onClick={handleNewTag(false)}
                        >
                          Discard
                        </Button>
                      </span>
                      <span>
                        <Button
                          size="medium"
                          disabled={!editTitle}
                          startIcon={<SaveIcon />}
                          variant="contained"
                          color="secondary"
                          sx={{ minWidth: 128 }}
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                      </span>
                    </Fragment> :

                    selectedIndex !== null ?
                      <Fragment>
                        <span>
                          <Button
                            size="medium"
                            disabled={selectedIndex !== null && getIsChanged()}
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            color="error"
                            sx={{ minWidth: 128 }}
                            onClick={handleDeleteTag}
                          >
                            Delete
                          </Button>
                        </span>
                        <span>
                          <Button
                            size="medium"
                            disabled={!getIsChanged()}
                            startIcon={<ResetIcon />}
                            variant="contained"
                            color="primary"
                            sx={{ minWidth: 128 }}
                            onClick={handleChangeCancel}
                          >
                            Reset
                          </Button>
                        </span>
                        <span>
                          <Button
                            size="medium"
                            disabled={!editTitle || !getIsChanged()}
                            startIcon={<SaveIcon />}
                            variant="contained"
                            sx={{ minWidth: 128 }}
                            color="secondary"
                            onClick={handleSaveChanges}
                          >
                            Save Changes
                          </Button>
                        </span>
                      </Fragment> : null
                  }
                  <span>
                    <Button
                      size="medium"
                      disabled={addingNewTag}
                      startIcon={<PlusIcon />}
                      variant="contained"
                      color="secondary"
                      sx={{ minWidth: 128 }}
                      onClick={handleNewTag(true)}
                    >
                      New Tag
                    </Button>
                  </span>
                </Stack>
              </Grid>
              <Grid item xs={12}>{addingNewTag || currentTitle ?
                <Stack direction="column"
                  justifyContent="center"
                  alignItems="center" spacing={2}>
                  <span>
                    <Chip
                      label={editTitle ? editTitle : "Sample tag"}
                      color="primary"
                      variant="outlined"
                      sx={{
                        borderColor: color.hex,
                        color: color.hex,
                        width: 'auto',
                        height: 32,
                        fontSize: 22,
                        padding: '1px 6px',
                        borderRadius: '4px'
                      }}
                    />
                  </span>
                  <span>
                    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center" spacing={2}>
                      <TextField
                        id="outlined-basic"
                        label="Tag name"
                        variant="outlined"
                        value={editTitle}
                        onChange={handleTagName}
                        autoComplete="off"
                      />
                      <Typography variant="body1">{APP_STORE_INFO.TAGS_PAGE_COLOR_PICKER}</Typography>
                      <ColorPicker width={325} height={175} color={color} onChange={setColor} hideHSV hideRGB dark />
                    </Stack>
                  </span>
                </Stack> :
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>
                  <Typography variant="overline" display="block">
                    Create a new tag or select one from the list to edit.
                  </Typography>
                </Box>
              }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {confirmationDialog && <ConfirmationDialog content={confirmationDialog} handleOpen={handleConfirmationDialog} />}
    </Fragment>
  )
};

export default TagsPage;
