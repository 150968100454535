import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    ListItem,
    Select,
    TextField,
} from '@material-ui/core';

import {
    ExternalLink as OpenInNew,
    Trash2 as DeleteIcon,
} from 'react-feather';

import { getOpenUrl } from '../../../utils/utils';
import { APP_CONFIG, VALIDATION } from '../../../config';


const useStyles = makeStyles((theme) => ({
    linkTextField: {
        paddingRight: '0px !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
    },
    linkListItem: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
    },
}));

const EMPTY_VALUE_PERIOD = "CUSTOM";

const LinksListItem = (props) => {
    const classes = useStyles();
    const functions = props.functions;
    const [period, setPeriod] = useState(EMPTY_VALUE_PERIOD);
    const [isDynamic, setIsDynamic] = useState(false);
    const [versionName, setVersionName] = useState("");

    const handlePeriod = (event) => {
        var value = event.target.value;
        if (value !== EMPTY_VALUE_PERIOD) {
            functions.handlePeriod && functions.handlePeriod(value, isDynamic, props.index);
        } else {
            functions.handlePeriod && functions?.handlePeriod(null, false, props.index);
        }

    };

    const handleIsDynamic = (event) => {
        functions.handlePeriod && functions.handlePeriod(period, event.target.checked, props.index);
    };

    const handleVersionName = (event) => {
        functions.handleVersionName && functions.handleVersionName(event.target.value, props.index);
    };

    const handleDeleteLinkElement = (index) => () => {
        functions.deleteLinkElement && functions.deleteLinkElement(index);
    };

    const getPeriodOptions = () => {
        let periodMap = {};

        APP_CONFIG.PERIODS.forEach((period) => {
            if (period?.type) {
                if (periodMap[period.type]) {
                    let tempArray = periodMap[period.type];
                    tempArray.push(period);
                    periodMap[period.type] = tempArray;
                } else {
                    periodMap[period.type] = [period];
                }
            }
        });

        return (
            <FormControl sx={{ minWidth: 150, maxWidth: 200, }}>
                <InputLabel id="demo-simple-select-helper-label">Validity</InputLabel>
                <Select
                    native
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={period}
                    label="Validity"
                    onChange={handlePeriod}
                    inputProps={{ readOnly: props.readOnly }}
                >
                    <option value={EMPTY_VALUE_PERIOD}>
                        Custom
                    </option>
                    {Object.keys(periodMap).map(type => (
                        <optgroup key={type} label={type}>
                            {periodMap[type].map(period => (
                                <option key={period.value} value={period.value}>{period.title}</option>
                            ))}
                        </optgroup>
                    ))}
                </Select>
                {/* <FormHelperText>With label + helper text</FormHelperText> */}
            </FormControl>
        );
    };

    useEffect(() => {
        if (props.period) {
            setPeriod(props.period);
        } else {
            setPeriod(EMPTY_VALUE_PERIOD);
        }
        setIsDynamic(Boolean(props.isDynamic));
        setVersionName(props.versionName);
    }, [props.index, props.link, props.isValid, props.isRecurring, props.period, props.isDynamic, props.versionName]);

    useEffect(() => { }, [props.readOnly])

    return (
        <>
            <ListItem key={props.index} className={classes.linkListItem}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required={!props.readOnly}
                            label="URL" name="Link URL"
                            aria-label="link"
                            error={Boolean((props.link?.link) && !props.isValid)}
                            onChange={(e) => { functions.handleLinks(e, props.index) }}
                            value={props.link.link}
                            autoComplete="off"
                            variant="outlined"
                            helperText={props.link.link && !props.isValid ? "URL not valid!" : !props.readOnly && props.link?.link?.length >= VALIDATION.LINK_LENGTH ? "Maximum character limit reached" : null}
                            InputProps={{
                                readOnly: props.readOnly,
                                classes: { root: classes.linkTextField },
                                endAdornment:
                                    <InputAdornment position="end">
                                        {props.link.link && props.isValid ?
                                            <Link href={getOpenUrl(props.link.link)} target="_blank" rel="noreferrer">
                                                <IconButton aria-label="Open" disabled={!props.isValid}>
                                                    <OpenInNew />
                                                </IconButton>
                                            </Link> : null}
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>
                {props.readOnly ? null :
                    <IconButton color="primary" aria-label="delete link" component="span" disabled={props.index === 0} style={{ visibility: (props.index === 0 && 'hidden') }}
                        onClick={handleDeleteLinkElement(props.index)}>
                        <DeleteIcon />
                    </IconButton>}
            </ListItem>
        </>
    );

}

export default LinksListItem;